<template>
  <div class="dashboard">
    <!-- Left Sidebar -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar">
          <img 
            v-if="profilePictureUrl" 
            :src="profilePictureUrl" 
            alt="Profile" 
            class="avatar-image"
          >
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
        </div>
        <span class="user-name">{{ authStore.user.username }}</span>
        <button class="logout-btn" @click="handleLogout" title="Logout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <!-- Keep the same nav-menu structure -->
      <nav class="nav-menu">
        <div class="nav-section">
          <h2>Projects</h2>
          <div 
            v-for="project in projects" 
            :key="project.id" 
            class="nav-item"
            :class="{ active: project.id === currentProjectId }"
            @click="navigateToProjectFromSidebar(project.id)"
          >
            <span>🎬</span>
            {{ project.name }}
          </div>
        </div>
      </nav>
      <div class="sidebar-footer">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Breadcrumb Navigation -->
      <div class="router-bar">
        <div class="breadcrumb">
          <span class="breadcrumb-item" @click="navigateToOrganization">{{ authStore.user.organization }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item" @click="navigateToProject">{{ projectName }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item active">{{ $t('properties.title') }}</span>
        </div>
      </div>

      <div class="content-header">
        <div class="header-left">
          <h1>{{ $t('properties.title') }}</h1>
        </div>
      </div>

      <!-- Properties Table -->
      <div class="properties-content">
        <div class="properties-grid">
          <div 
            v-for="property in properties" 
            :key="property.id" 
            class="property-card"
            @click="navigateToProperty(property.id)"
          >
            <div class="property-icon">📦</div>
            <div class="property-details">
              <h3>{{ $t(property.name) }}</h3>
          <!-- <p class="property-count">0 items</p> -->    
            </div>
          </div>

          <!-- Add New Property Card -->
          <div 
            class="property-card add-property"
            @click="openNewPropertyModal"
          >
            <div class="add-property-content">
              <span class="add-icon">+</span>
              <span>{{ $t('properties.addNew') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useAuthStore } from '../stores/auth'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

export default {
  name: 'ProjectProperties',

  setup() {
    const currentProjectId = ref('')
    const currentPropertyId = ref('')
    const profilePictureUrl = ref(null)
    const projects = ref([])
    const authStore = useAuthStore()
    const { locale } = useI18n()
    // Mock properties data
    const properties = ref([
      { id: '1', name: 'properties.locations' },
      { id: '2', name: 'properties.cast' },
      { id: '3', name: 'properties.props' },
      { id: '4', name: 'properties.specials' }
    ])

    const projectName = computed(() => {
      const currentProject = projects.value.find(p => p.id === currentProjectId.value)
      return currentProject ? currentProject.name : ''
    })

    return {
      currentProjectId,
      currentPropertyId,
      profilePictureUrl,
      authStore,
      properties,
      projects,
      projectName,
      locale
    }
  },

  methods: {
    navigateToProperty(propertyId) {
      this.$router.push({
        name: 'PropertyDetails',
        params: {
          projectId: this.currentProjectId,
          propertyId: propertyId
        }
      })
    },

    navigateToProject() {
      this.$router.push(`/project/${this.currentProjectId}`)
    },

    navigateToOrganization() {
      this.$router.push('/dashboard')
    },

    openNewPropertyModal() {
      // Implement modal opening logic
    },
    async loadProjects() {
      try {
        const response = await axios.get('/projects', {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    async navigateToProjectFromSidebar(projectId) {
      try {
        const response = await axios.get(`/projects/${projectId}`, {
          withCredentials: true
        })
        if (response.data) {
          this.$router.push(`/project/${projectId}`)
        }
      } catch (error) {
        console.error('Failed to navigate to project:', error)
      }
    },

    async handleLogout() {
      try {
        await axios.post('/user/v1/logout', {}, {
          withCredentials: true
        })
        this.$router.push('/login')
      } catch (error) {
        console.error('Logout failed:', error)
        // Still redirect to login even if the logout request fails
        this.$router.push('/login')
      }
    }
  },

  mounted() {
    this.currentProjectId = this.$route.params.projectId
    this.loadProjects()
  }
}
</script>

<style scoped>
.main-content {
  flex: 1;
  position: relative;
  min-width: 0;
}

.router-bar {
  padding: 16px 24px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;
}

.breadcrumb-item {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.breadcrumb-item:hover {
  opacity: 1;
}

.breadcrumb-item.active {
  opacity: 1;
  font-weight: 500;
  cursor: default;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.properties-content {
  padding: 24px;
}

.properties-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  padding: 16px;
}

.property-card {
  background: #2c2c2c;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid #3e3e3e;
}

.property-card:hover {
  transform: translateY(-2px);
  background: #333333;
}

.property-icon {
  font-size: 24px;
  width: 48px;
  height: 48px;
  background: #1e1e1e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.property-details {
  flex: 1;
}

.property-details h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.property-count {
  margin: 4px 0 0;
  font-size: 13px;
  color: #888;
}

.add-property {
  border: 2px dashed #3e3e3e;
  background: transparent;
}

.add-property:hover {
  border-color: #2962ff;
  background: rgba(41, 98, 255, 0.1);
}

.add-property-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #888;
}

.add-icon {
  font-size: 24px;
  font-weight: 300;
}

.add-property:hover .add-property-content {
  color: #2962ff;
}
</style>