import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import App from './App.vue'
import en from './locales/en.json'
import de from './locales/de.json'
import { createRouter, createWebHistory } from 'vue-router'
import './assets/main.css'
import axios from 'axios'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura';

import UserLogin from './components/UserLogin.vue'
import UserDashboard from './components/UserDashboard.vue'
import ProjectOverview from './components/ProjectOverview.vue'
import ScriptBreakdown from './components/ScriptBreakdown.vue'
import ShootingSchedule from './components/ShootingSchedule.vue'
import ProjectProperties from './components/ProjectProperties.vue'
import PropertyDetails from './components/PropertyDetails.vue'
import ProjectContacts from './components/ProjectContacts.vue'
import { useAuthStore } from './stores/auth'

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://api.fastwrap.io'

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'en',
  messages: {
    en,
    de
  }
})

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login', 
        component: UserLogin},
    {
        path: '/dashboard', 
        component: UserDashboard,
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.loggedIn) {
                next();
            } else {
                next('/login');
            }
        },
    },
    {
        path: '/project/:projectId',
        name: 'ProjectOverview',
        component: ProjectOverview
      },
      {
        path: '/project/:projectId/script-breakdown',
        name: 'ScriptBreakdown',
        component: ScriptBreakdown
      },
      {
        path: '/project/:projectId/shooting-schedule',
        name: 'ShootingSchedule',
        component: ShootingSchedule
      },
      {
        path: '/project/:projectId/properties',
        name: 'ProjectProperties',
        component: ProjectProperties
      },
      {
        path: '/project/:projectId/properties/:propertyId',
        name: 'PropertyDetails',
        component: PropertyDetails
      },
      {
        path: '/project/:projectId/contacts',
        name: 'ProjectContacts',
        component: ProjectContacts
      }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(createPinia())
app.use(i18n)
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: 'system',
      cssLayer: false 
    }
  }
})
app.use(router);
app.mount('#app');
