<template>
  <div class="dashboard">
    <!-- Left Sidebar -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar" @click="triggerFileInput">
          <img 
            v-if="profilePictureUrl" 
            :src="profilePictureUrl" 
            alt="Profile" 
            class="avatar-image"
          >
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
          <input 
            type="file" 
            ref="fileInput" 
            @change="uploadProfilePicture" 
            accept="image/*" 
            class="hidden-input"
          >
        </div>
        <span class="user-name">{{ authStore.user.username }}</span>
        <button class="logout-btn" @click="handleLogout" title="Logout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <nav class="nav-menu">
        <div class="nav-section">
          <h2>{{ $t('sidebar.projects') }}</h2>
          <div v-for="project in projects" :key="project.id" class="nav-item" @click="navigateToProject(project.id)">
            <span>🎬</span>
            {{ project.name }}
          </div>
        </div>
      </nav>

      <!-- Add language selector at bottom of sidebar -->
      <div class="sidebar-footer">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <div class="header-gradient">
        <div class="logo-container">
          <img 
            :src="getOrganizationLogo" 
            :alt="authStore.user.organization" 
            class="logo"
          >
        </div>
      </div>
      
      <div class="content-header">
        <div class="header-left">
          <h1>{{ authStore.user.organization.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ') }}</h1>
          <div class="filter">
            <select class="filter-select">
              <option value="modified">{{ $t('dashboard.lastModified') }}</option>
              <option value="name">{{ $t('dashboard.name') }}</option>
              <option value="created">{{ $t('dashboard.dateCreated') }}</option>
            </select>
          </div>
        </div>
        <div class="header-right">
          <div class="new-project-form">
            <button 
              @click="handleCreateProject"
              class="new-project-btn"
            >
              <span>+</span> {{ $t('dashboard.createProjectButton') }}
            </button>
          </div>
        </div>
      </div>

      <div class="projects-container">
        <div class="projects-grid">
          <div v-for="project in projects" :key="project.id" class="project-card" @click="navigateToProject(project.id)">
            <div class="project-preview">
              <!-- Project preview/thumbnail -->
            </div>
            <div class="project-info">
              <h3>{{ project.name }}</h3>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add the modal -->
  <div v-if="showCreateModal" class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <h2>{{ $t('dashboard.createProject') }}</h2>
        <button class="close-btn" @click="showCreateModal = false">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <div class="modal-content">
        <div class="project-form">
          <div class="form-group">
            <input 
              type="text" 
              v-model="newProjectName"
              :placeholder="$t('dashboard.projectTitle')"
              class="project-input"
            >
          </div>
          
          <div class="form-group">
            <select 
              v-model="newProjectCategory" 
              class="project-input"
            >
              <option value="" disabled>{{ $t('dashboard.projectCategory') }}</option>
              <option value="movie">{{ $t('dashboard.movieCategory') }}</option>
              <option value="tv-show">{{ $t('dashboard.tvShowCategory') }}</option>
              <option value="advertisment">{{ $t('dashboard.advertismentCategory') }}</option>
              <option value="documentary">{{ $t('dashboard.documentaryCategory') }}</option>
              <option value="animation">{{ $t('dashboard.animationCategory') }}</option>
              <option value="Commercial">{{ $t('dashboard.commercialCategory') }}</option>
              <option value="Live Production">{{ $t('dashboard.liveProductionCategory') }}</option>
              <option value="Experimental">{{ $t('dashboard.experimentalCategory') }}</option>
            </select>
          </div>

          <div class="upload-area">
            <div 
              class="upload-box"
              @dragover.prevent
              @drop.prevent="handleFileDrop"
              @click="triggerScriptFileInput"
            >
              <input 
                type="file"
                ref="scriptFileInput"
                @change="handleScriptFileSelect"
                accept=".pdf"
                class="hidden-input"
              >
              <template v-if="selectedFile">
                <p class="selected-file">
                  <span class="file-name">{{ selectedFile.name }}</span>
                  <button class="remove-file" @click.stop="removeFile">×</button>
                </p>
              </template>
              <template v-else>
                <p>{{ $t('dashboard.uploadAreaText') }}</p>
                <p>{{ $t('dashboard.uploadAreaTextRow2') }}</p>
                <button class="upload-btn">{{ $t('dashboard.uploadAreaButton') }}</button>
              </template>
            </div>
          </div>

          <div class="important-note">
            <p><span>{{ $t('dashboard.importantNote') }}:</span> {{ $t('dashboard.importantNoteText') }}</p>
          </div>

          <!-- Add modal actions -->
          <div class="modal-actions">
            <button 
              class="create-project-btn" 
              @click="createProject"
              :disabled="!newProjectName || !selectedFile || isLoading"
            >
              <span v-if="isLoading" class="spinner"></span>
              <span v-if="isLoading">{{ loadingStatus }}</span>
              <span v-else>{{ $t('dashboard.createProjectButtonModal') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

export default {
  name: 'UserDashboard',

  data() {
    return {
      newProjectCategory: 'movie',
      isLoading: false,
      loadingStatus: '',
    }
  },

  setup() {
    const authStore = useAuthStore()
    const projects = ref([])
    const newProjectName = ref('')
    const fileInput = ref(null)
    const profilePictureUrl = ref(null)
    const showCreateModal = ref(false)
    const scriptFileInput = ref(null)
    const selectedFile = ref(null)
    const { locale } = useI18n()

    return {
      authStore,
      projects,
      newProjectName,
      fileInput,
      profilePictureUrl,
      showCreateModal,
      scriptFileInput,
      selectedFile,
      locale
    }
  },

  methods: {
    async loadProjects() {
      try {
        const response = await axios.get(`/projects`, {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    handleCreateProject() {
      this.showCreateModal = true
    },

    handleLogout() {
      this.authStore.logout()
      this.$router.push('/login')
    },

    triggerFileInput() {
      this.fileInput.click()
    },

    async uploadProfilePicture(event) {
      const file = event.target.files[0]
      if (!file) return

      const formData = new FormData()
      formData.append('file', file)

      try {
        const response = await axios.post(`/organization/profile-picture`, 
          formData,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        this.profilePictureUrl = response.data.profile_picture_url
      } catch (error) {
        console.error('Failed to upload profile picture:', error)
      }
    },

    triggerScriptFileInput() {
      this.scriptFileInput.click()
    },

    handleScriptFileSelect(event) {
      const file = event.target.files[0]
      if (file) {
        this.handleScriptFile(file)
      }
    },

    handleFileDrop(event) {
      const file = event.dataTransfer.files[0]
      if (file) {
        this.handleScriptFile(file)
      }
    },

    handleScriptFile(file) {
      if (file.type !== 'application/pdf') {
        alert('Please upload a PDF file')
        return
      }
      this.selectedFile = file
      console.log('File to upload:', file)
    },

    removeFile(event) {
      this.event = event
      this.selectedFile = null
      this.scriptFileInput.value = ''
    },

    async createProject() {
      this.isLoading = true
      try {
        // 1. Create the project
        this.loadingStatus = this.$t('dashboard.loadingStatus1')
        const projectData = { 
          name: this.newProjectName,
          category: this.newProjectCategory
        }
        
        console.log('Sending project data:', projectData)
        const response = await axios.post('/projects', 
          projectData,
          {
            withCredentials: true
          }
        )

        const project = response.data
        console.log('Project created:', project)

        // 2. Upload screenplay if selected
        if (this.selectedFile) {
          this.loadingStatus = this.$t('dashboard.loadingStatus2')
          const formData = new FormData()
          formData.append('file', this.selectedFile)

          // Upload the screenplay
          await axios.post(
            `/projects/${project.project_id}/screenplays`,
            formData,
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )

          // 3. Create scenes from the uploaded screenplay
          this.loadingStatus = this.$t('dashboard.loadingStatus3')
          await axios.post(
            `/projects/${project.project_id}/create-scenes`,
            {},
            {
              withCredentials: true
            }
          )

          // 4. Process properties
          this.loadingStatus = this.$t('dashboard.loadingStatus4')
          // Add a small delay to show the final status
          await new Promise(resolve => setTimeout(resolve, 1000))
        }

        // 5. Refresh projects and reset form
        await this.loadProjects()
        this.newProjectName = ''
        this.selectedFile = null
        this.showCreateModal = false
      } catch (error) {
        console.error('Error creating project:', error)
        const errorMessage = error.response?.data?.message || error.message
        alert(errorMessage)
      } finally {
        this.isLoading = false
        this.loadingStatus = ''
      }
    },

    navigateToProject(projectId) {
      this.$router.push(`/project/${projectId}`)
    }
  },

  mounted() {
    const savedProfilePicture = localStorage.getItem('profilePictureUrl')
    if (savedProfilePicture) {
      this.profilePictureUrl = savedProfilePicture
    }
    
    this.loadProjects()
  },

  computed: {
    getOrganizationLogo() {
      const org = this.authStore.user.organization.toLowerCase()
      switch (org) {
        case 'florida film':
          return require('../assets/florida-film.png')
        case 'studiozentral':
          return require('../assets/studiozentral.png')
        case 'bavaria fiction':
          return require('../assets/bavaria-fiction.png')
        default:
          return require('../assets/florida-film.png') // fallback logo
      }
    }
  }
}
</script>

<style scoped>
.dashboard {
  display: flex;
  height: 100vh;
  background: #1e1e1e;
  color: #ffffff;
  overflow-x: hidden;
}

.sidebar {
  width: 200px;
  background: #2c2c2c;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #3e3e3e;
  position: sticky;
  top: 0;
  height: auto;
  overflow-y: auto;
}

.brand {
  padding: 16px;
  margin-bottom: 24px;
}

.logo {
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
}

.nav-section h2 {
  font-size: 12px;
  text-transform: uppercase;
  color: #888;
  margin-bottom: 8px;
  padding: 0 12px;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #ffffff;
  text-decoration: none;
  gap: 8px;
}

.nav-item:hover {
  background: #363636;
}

.main-content {
  flex: 1;
  padding: 24px 24px 24px 0;
  overflow-y: auto;
  min-width: 0;
  position: relative;
  height: 100vh;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 16px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.new-project-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.projects-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: calc(100% - 130px);
  margin-left: 100px;
  z-index: 1;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;
}

.project-card {
  background: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.project-preview {
  height: 180px;
  background: #363636;
}

.project-info {
  padding: 16px;
}

.view-controls {
  display: flex;
  gap: 8px;
}

.view-btn {
  background: #363636;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  color: #888;
}

.view-btn.active {
  background: #4a4a4a;
  color: white;
}

.user-section {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  margin-bottom: 24px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  background: #3e3e3e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.user-name {
  flex: 1;
  font-size: 14px;
  color: #ffffff;
}

.logout-btn {
  background: transparent;
  border: none;
  color: #888;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.logout-btn:hover {
  color: #ffffff;
  background: #363636;
}

.new-project-form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.new-project-input {
  padding: 8px;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  background: #2c2c2c;
  color: white;
}

.action-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.action-btn:disabled {
  background: #4a4a4a;
  cursor: not-allowed;
}

.header-gradient {
  height: 200px;
  background: linear-gradient(180deg, 
    rgba(55, 65, 81, 1) 0%,
    rgba(30, 30, 30, 1) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.logo-container {
  position: absolute;
  bottom: -40px;
  left: 100px;
  z-index: 3;
}

.logo {
  width: 100px;
  height: 100px;
  background: #2c2c2c;
  border-radius: 8px;
  padding: 8px;
}

.content-header {
  position: relative;
  z-index: 2;
  margin-top: 200px;
  padding: 0 24px;
  margin-bottom: 48px;
}

.header-left {
  margin-left: 75px;
}

@media (max-width: 1024px) {
  .main-content {
    padding: 24px 16px;
  }

  .projects-container {
    max-width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .header-left {
    margin-left: 0;
  }

  .logo-container {
    left: 50%;
    transform: translateX(-50%);
  }

  .content-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 140px;
  }
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #3e3e3e;
  }

  .projects-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .main-content {
    padding: 16px;
  }
}

.filter-select {
  background: #2c2c2c;
  color: #ffffff;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  min-width: 140px;
  appearance: none; /* Remove default arrow */
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 36px;
}

.filter-select:hover {
  border-color: #4a4a4a;
}

.filter-select:focus {
  border-color: #2962ff;
}

.filter-select option {
  background: #2c2c2c;
  color: #ffffff;
  padding: 8px;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hidden-input {
  display: none;
}

.user-avatar:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #2c2c2c;
  border-radius: 8px;
  width: 90%;
  max-width: 450px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  margin: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.modal-header h2 {
  color: #ffffff;
  margin: 0;
  width: 100%;
}

.close-btn {
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 4px;
}

.close-btn:hover {
  color: #ffffff;
}

.modal-content {
  padding: 0 10px;
}

.project-form {
  gap: 12px;
}

.form-group {
  margin-bottom: 12px;
}

.project-input {
  width: 100%;
  padding: 10px;
  background: #1e1e1e;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
  box-sizing: border-box;
}

.upload-area {
  margin: 12px 0;
}

.upload-box {
  border: 2px dashed #3e3e3e;
  border-radius: 8px;
  padding: 30px 20px;
  text-align: center;
  color: #888;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-box:hover {
  border-color: #2962ff;
  background: rgba(41, 98, 255, 0.05);
}

.upload-box.drag-over {
  border-color: #2962ff;
  background: rgba(41, 98, 255, 0.1);
}

.upload-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 12px;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.upload-btn:hover {
  background: #1e4fc4;
}

.important-note {
  margin-top: 12px;
  padding: 10px;
  border-radius: 4px;
  background: rgba(41, 98, 255, 0.1);
  color: #888;
  font-size: 13px;
  line-height: 1.4;
}

.important-note span {
  color: #2962ff;
  font-style: italic;
  font-weight: 500;
}

.selected-file {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #ffffff;
  margin: 0;
}

.file-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-file {
  background: transparent;
  border: none;
  color: #888;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 1;
}

.remove-file:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.modal-actions {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.create-project-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
}

.create-project-btn:hover {
  background: #1e4fc4;
}

.create-project-btn:disabled {
  background: #4a4a4a;
  cursor: not-allowed;
}

.modal::-webkit-scrollbar {
  width: 8px;
}

.modal::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-radius: 4px;
}

.modal::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border-radius: 4px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

</style>
