<template>
  <div class="dashboard">
    <!-- Left Sidebar -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar">
          <img 
            v-if="profilePictureUrl" 
            :src="profilePictureUrl" 
            alt="Profile" 
            class="avatar-image"
          >
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
        </div>
        <span class="user-name">{{ authStore.user.username }}</span>
        <button class="logout-btn" @click="handleLogout" title="Logout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <nav class="nav-menu">
        <div class="nav-section">
          <h2>{{ $t('sidebar.projects') }}</h2>
          <div 
            v-for="project in projects" 
            :key="project.id" 
            class="nav-item"
            :class="{ active: project.id === currentProjectId }"
            @click="navigateToProjectFromSidebar(project.id)"
          >
            <span>🎬</span>
            {{ project.name }}
          </div>
        </div>
      </nav>
      <div class="sidebar-footer">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Breadcrumb Navigation -->
      <div class="router-bar">
        <div class="breadcrumb">
          <span class="breadcrumb-item" @click="navigateToOrganization">{{ authStore.user.organization }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item" @click="navigateToProject">{{ projectName }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item active">{{ $t('breakdown.title') }}</span>
        </div>
      </div>

      <div class="content-header">
        <div class="header-left">
          <h1>{{ $t('breakdown.title') }}</h1>
        </div>
      </div>

      <!-- Scene Table -->
      <div class="scene-content">
        <DataTable 
          :value="scenes" 
          :scrollable="true"
          scrollHeight="600px"
          :resizableColumns="true"
          columnResizeMode="fit"
          class="p-datatable-sm"
          v-model:selection="selectedScenes"
          :sortField="sortField"
          :sortOrder="sortOrder"
          @sort="onSort"
          selectionMode="multiple"
          dataKey="id"
          stripedRows
        >
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          
          <Column field="sceneNumber" :header="$t('breakdown.scene')" sortable style="width: 50px">
            <template #body="slotProps">
              <span class="scene-number">{{ slotProps.data.sceneNumber }}</span>
            </template>
          </Column>

          <Column field="day" :header="$t('breakdown.day')" sortable style="width: 50px"></Column>
          
          <Column field="pageLength" :header="$t('breakdown.pagelength')" sortable style="width: 100px">
            <template #body="slotProps">
              {{ slotProps.data.pageLength }}
            </template>
          </Column>

          <Column field="motif" :header="$t('breakdown.sceneDescription')" sortable style="width: 250px">
            <template #body="slotProps">
              <div class="scene-description" :title="slotProps.data.fullSynopsis">
                <div class="motif">{{ slotProps.data.motif }}</div>
                <div class="synopsis">{{ slotProps.data.shortSynopsis }}</div>
              </div>
            </template>
          </Column>

          <Column field="status" :header="$t('breakdown.status')" sortable style="width: 100px">
            <template #body="slotProps">
              <span :class="['status-tag', slotProps.data.status.toLowerCase()]">
                {{ slotProps.data.status }}
              </span>
            </template>
          </Column>

          <Column field="actors" :header="$t('breakdown.cast')" style="width: 150px">
            <template #body="slotProps">
              <div class="cast-preview" :title="slotProps.data.actors.sort().join(', ')">
                <span v-if="slotProps.data.actors.length <= 2">
                  {{ slotProps.data.actors.sort().join(', ') }}
                </span>
                <span v-else>
                  {{ slotProps.data.actors.sort().slice(0, 2).join(', ') }}
                  <span class="more-actors">+{{ slotProps.data.actors.length - 2 }}</span>
                  <div class="actors-tooltip">
                    {{ slotProps.data.actors.sort().join(', ') }}
                  </div>
                </span>
              </div>
            </template>
          </Column>

          <Column field="prestop" :header="$t('breakdown.prestop')" sortable style="width: 100px">
            <template #body="slotProps">
              <span class="duration-tag">{{ slotProps.data.prestop }}</span>
            </template>
          </Column>

          <Column field="duration" :header="$t('breakdown.duration')" sortable style="width: 100px">
            <template #body="slotProps">
              <span 
                :class="['duration-tag', { 'unset': !slotProps.data.duration }]"
                @click="slotProps.data.duration === undefined && editScene(slotProps.data)"
              >
                {{ slotProps.data.duration ? `${slotProps.data.duration}min` : $t('breakdown.setTime') }}
              </span>
            </template>
          </Column>

          <Column field="edit" :header="$t('breakdown.inspector')" style="width: 80px">
            <template #body="slotProps">
              <PrimeButton
                class="duration-tag" 
                :label="$t('breakdown.edit')"
                @click="editScene(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <!-- Add loading state -->
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

    <!-- Add error message -->
    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <!-- Edit Modal -->
    <div 
      v-if="showEditModal" 
      class="modal-overlay"
      @click.self="closeEditModal"
    >
      <div class="modal">
        <div class="modal-header">
          <div class="scene-info-grid">
            <div class="top-row">
              <div class="left-section">
                <div class="scene-block">
                  <div class="label">SCENE</div>
                  <div class="value">{{ editingScene.sceneNumber }}</div>
                </div>
                
                <div class="dn-block">
                  <div class="label">D / N</div>
                  <div class="value">{{ editingScene.location_type }}/{{ editingScene.time_of_day === 'TAG' ? 'Day' : 'Night' }}</div>
                </div>
                
                <div class="day-block">
                  <div class="label">DAY</div>
                  <div class="value">2</div> <!-- TODO: get day from database -->
                </div>
              </div>

              <div class="right-section">
                <div class="pages-block">
                  <div class="label">PAGES</div>
                  <div class="value">2/8</div>
                </div>
                
                <div class="prestop-block">
                  <div class="label">PRE-STOP</div>
                  <div class="value">01:30</div>
                </div>
                
                <div class="duration-block">
                  <div class="label">DURATION</div>
                  <div class="value">45min</div>
                </div>
              </div>
            </div>

            <div class="motif-row">
              <div class="left-section">
                <div class="motif-block">
                  <div class="label">MOTIF</div>
                  <div class="value">{{ editingScene.location }}</div>
                </div>
              </div>

              <div class="right-section">
                <div class="synopsis-block">
                  <div class="label">SYNOPSIS</div>
                  <div class="value">{{ editingScene.synopsis }}</div>            
                </div>
              </div>
            </div>
            <div class="cast-row">
              <div class="left-section">
                <div class="cast-block">
                  <div class="label">CAST</div>
                  <div class="value">{{ editingScene.actors.join(', ') }}</div>
                </div>
              </div>
              <div class="right-section">
                <div class="props-block">
                  <div class="label">PROPS</div>
                  <div class="value">{{ editingScene.props.join(', ') }}</div>
                </div>
              </div>
            </div>
            <div class="specials-row">
              <div class="special-icons">
                <div class="special-icon" :class="{ active: editingScene.hasVehicles }" title="Vehicles">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 17H17M6 11L7.43496 6.53819C7.50954 6.31554 7.66062 6.12325 7.86319 5.98868C8.06576 5.85412 8.30932 5.78334 8.55837 5.78832L15.4416 5.78832C15.6907 5.78334 15.9342 5.85412 16.1368 5.98868C16.3394 6.12325 16.4905 6.31554 16.565 6.53819L18 11M6 11H18M6 11L5 15H19L18 11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="special-icon" :class="{ active: editingScene.hasAnimals }" title="Animals">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 5.172C10.9473 4.42666 12.1546 4 13.4546 4C16.1288 4 18.2727 6.14335 18.2727 8.81818C18.2727 11.4931 16.1288 13.6364 13.4546 13.6364C12.1546 13.6364 10.9473 13.2097 10 12.4644M13.4546 8.81818C13.4546 12.5495 10.4332 15.5714 6.72727 15.5714C3.02132 15.5714 0 12.5495 0 8.81818C0 5.08686 3.02132 2.06497 6.72727 2.06497C10.4332 2.06497 13.4546 5.08686 13.4546 8.81818Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="special-icon" :class="{ active: editingScene.hasStunts }" title="Stunts">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 2L3 14H12L11 22L21 10H12L13 2Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="special-icon" :class="{ active: editingScene.hasCostumes }" title="Costumes">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.34315 17.6569C5.22433 16.538 4.4624 15.1126 4.15372 13.5607C3.84504 12.0089 4.00346 10.4003 4.60896 8.93853C5.21447 7.47672 6.23985 6.22729 7.55544 5.34824C8.87103 4.46919 10.4177 4 12 4C13.5823 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 4V12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="cancel-btn" @click="closeEditModal">Cancel</button>
          <button 
            class="save-btn" 
            @click="saveScene"
            :disabled="isSaving"
          >
            <span v-if="isSaving" class="spinner"></span>
            <span v-else>Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { orderBy } from 'lodash-es'
import { useAuthStore } from '../stores/auth'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

// Import PrimeVue components
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import PrimeButton from 'primevue/button'

export default {
  name: 'ScriptBreakdown',
  
  components: {
    DataTable,
    Column,
    PrimeButton
  },

  data() {
    return {
      scenes: [],
      loading: false,
      error: null,
      filters: {
        description: '',
        status: '',
        cast: '',
        setting: '',
        duration: '',
        prestop: ''
      },
      sortField: null,
      sortOrder: null,
      showEditModal: false,
      editingScene: null,
      newActor: '',
      newProp: '',
      newSpecial: '',
      isSaving: false
    }
  },

  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const currentProjectId = ref('')
    const profilePictureUrl = ref(null)
    const projects = ref([])
    const { locale } = useI18n()
    const selectedScenes = ref([])

    // Add computed property for project name
    const projectName = computed(() => {
      const currentProject = projects.value.find(p => p.id === currentProjectId.value)
      return currentProject ? currentProject.name : ''
    })

    return {
      currentProjectId,
      profilePictureUrl,
      projectName,
      router,
      projects,
      authStore,
      locale,
      selectedScenes
    }
  },

  computed: {
    filteredScenes() {
      return this.scenes.filter(scene => {
        // Description filter
        if (this.filters.description && 
            !`${scene.motif} ${scene.shortSynopsis}`.toLowerCase()
              .includes(this.filters.description.toLowerCase())) {
          return false
        }

        // Status filter
        if (this.filters.status && 
            scene.status.toLowerCase() !== this.filters.status.toLowerCase()) {
          return false
        }

        // Properties filter
        if (this.filters.properties && 
            scene.propertiesCount !== parseInt(this.filters.properties)) {
          return false
        }

        // Cast filter
        if (this.filters.cast && 
            !scene.actors.some(actor => 
              actor.toLowerCase().includes(this.filters.cast.toLowerCase()))) {
          return false
        }

        // Location filter
        if (this.filters.location && 
            !scene.location.toLowerCase().includes(this.filters.location.toLowerCase())) {
          return false
        }

        // Setting filter
        if (this.filters.setting && scene.setting !== this.filters.setting) {
          return false
        }

        // Duration filter
        if (this.filters.duration) {
          if (this.filters.duration === 'missing' && scene.duration !== -1) {
            return false
          }
          if (this.filters.duration === 'set' && scene.duration === -1) {
            return false
          }
        }

        if (this.filters.prestop) {
          if (this.filters.prestop === 'missing' && scene.prestop !== 0) {
            return false
          }
        }

        return true
      })
    },

    sortedScenes() {
      if (!this.sortField) return this.filteredScenes

      const getValue = (scene) => {
        switch (this.sortField) {
          case 'status':
            // Active first
            return scene.status === 'Active' ? 0 : 1
          case 'properties':
            return scene.propertiesCount
          case 'location':
            return scene.location.toLowerCase()
          case 'setting':
            // Day first
            return scene.setting === 'Day' ? 0 : 1
          case 'duration':
            // Set duration first (-1 means unset)
            return scene.duration === -1 ? Number.MAX_VALUE : scene.duration
          default:
            return scene[this.sortField]
        }
      }

      return orderBy(this.filteredScenes, [getValue], [this.sortOrder])
    }
  },

  methods: {
    async loadProjects() {
      try {
        const response = await axios.get(`/projects`, {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    navigateToProject() {
      this.router.push(`/project/${this.currentProjectId}`)
    },

    navigateToOrganization() {
      this.router.push('/dashboard')
    },

    navigateToProjectFromSidebar(projectId) {
      this.router.push(`/project/${projectId}`)
    },

    handleLogout() {
      this.router.push('/login')
    },

    toggleAllScenes(event) {
      const checked = event.target.checked
      this.scenes.forEach(scene => scene.selected = checked)
    },

    async loadScenes() {
      this.loading = true
      try {
        const response = await axios.get(
          `/projects/${this.currentProjectId}/retrieve-scenes`,
          {
            withCredentials: true
          }
        )

        const data = response.data
        this.scenes = data.map(scene => ({
          id: scene.id,
          selected: false,
          sceneNumber: scene.scene_nr,
          motif: `${scene.location_type}. ${scene.motif}`,
          shortSynopsis: scene.synopsis.substring(0, 50) + '...',
          fullSynopsis: scene.synopsis,
          status: 'Active',
          propertiesCount: scene.props.length,
          props: scene.props,
          actors: scene.actors,
          location: scene.location_name,
          setting: scene.time_of_day === 'TAG' ? 'Day' : 'Night',
          duration: scene.duration > 0 ? scene.duration : undefined,
          day: scene.play_day, // Math.floor(Math.random() * 20) + 1,
          pageLength: scene.page_length,
          prestop: (() => {
            const seconds = Math.floor(Math.random() * 81) + 10; // Random between 10 and 90 seconds
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
          })()
        }))
      } catch (error) {
        console.error('Error loading scenes:', error)
        this.error = error.response?.data?.message || error.message
      } finally {
        this.loading = false
      }
    },

    applyFilters() {
      // Method stub for future backend integration
      console.log('Filters applied:', this.filters)
    },

    toggleSort(field) {
      if (this.sortField === field) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortField = field
        // Set initial sort order based on field
        switch (field) {
          case 'status':
          case 'setting':
          case 'duration':
            this.sortOrder = 'asc' // Active/Day/Set first
            break
          case 'properties':
            this.sortOrder = 'desc' // Most properties first
            break
          case 'location':
            this.sortOrder = 'asc' // A-Z
            break
          default:
            this.sortOrder = 'asc'
        }
      }
    },

    editScene(scene) {
      this.editingScene = {
        ...scene,
        location_type: scene.motif.split('.')[0],
        motiv: scene.motif.split('.')[1].trim(),
        synopsis: scene.fullSynopsis,
        time_of_day: scene.setting === 'Day' ? 'TAG' : 'NACHT',
        actors: [...scene.actors],
        props: scene.props || [],
        specials: scene.specials || [],
        duration: scene.duration,
        day: scene.day,
        pageLength: scene.pageLength,
        prestop: scene.prestop,
        hasVehicles: scene.hasVehicles || false,
        hasAnimals: scene.hasAnimals || false,
        hasStunts: scene.hasStunts || false,
        hasCostumes: scene.hasCostumes || false,
        location: scene.location
      }
      this.showEditModal = true
    },

    closeEditModal() {
      this.showEditModal = false
      this.editingScene = null
      this.newActor = ''
      this.newProp = ''
      this.newSpecial = ''
    },

    addActor() {
      if (this.newActor.trim()) {
        this.editingScene.actors.push(this.newActor.trim())
        this.newActor = ''
      }
    },

    removeActor(index) {
      this.editingScene.actors.splice(index, 1)
    },

    addProp() {
      if (this.newProp.trim()) {
        this.editingScene.props.push(this.newProp.trim())
        this.newProp = ''
      }
    },

    removeProp(index) {
      this.editingScene.props.splice(index, 1)
    },

    addSpecial() {
      if (this.newSpecial.trim()) {
        this.editingScene.specials.push(this.newSpecial.trim())
        this.newSpecial = ''
      }
    },

    removeSpecial(index) {
      this.editingScene.specials.splice(index, 1)
    },

    async saveScene() {
      this.isSaving = true
      try {
        await axios.put(
          `/projects/${this.currentProjectId}/scenes/${this.editingScene.id}`,
          {
            location_type: this.editingScene.location_type,
            time_of_day: this.editingScene.time_of_day,
            synopsis: this.editingScene.synopsis,
            motiv: this.editingScene.motiv,
            actors: this.editingScene.actors,
            props: this.editingScene.props,
            specials: this.editingScene.specials,
            duration: this.editingScene.duration ? parseInt(this.editingScene.duration) : 0,
            prestop: this.editingScene.prestop ? parseInt(this.editingScene.prestop) : 0,
            location: this.editingScene.location
          },
          {
            withCredentials: true
          }
        )

        // Reload scenes to get updated data
        await this.loadScenes()
        this.closeEditModal()
      } catch (error) {
        console.error('Error saving scene:', error)
        alert(error.response?.data?.message || error.message)
      } finally {
        this.isSaving = false
      }
    },

    onSort(event) {
      this.sortField = event.sortField
      this.sortOrder = event.sortOrder
    }
  },

  mounted() {
    this.currentProjectId = this.$route.params.projectId
    this.loadProjects()
    this.loadScenes()
  }
}
</script>

<style scoped>
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 16px;
}

.main-content {
  flex: 1;
  padding: 24px 24px 24px 0;
  overflow-y: auto;
  min-width: 0;
  position: relative;
  height: 100vh;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.scene-content {
  margin: 0 24px;
  padding: 24px;

  .p-datatable {
    background: #1a1a1a;
    border-radius: 8px;

    .p-datatable-header {
      background: #1a1a1a;
      border: none;
    }

    .p-datatable-thead > tr > th {
      background: #1a1a1a;
      border: none;
      color: #888;
      font-weight: 500;
      padding: 1rem;
    }

    .p-datatable-tbody > tr {
      background: #1e1e1e;
      border-bottom: 1px solid #333;

      &:hover {
        background: #252525;
      }

      > td {
        border: none;
        padding: 1rem;
      }
    }
  }
}

.scene-header .scene-number {
  font-size: 14px;
  font-weight: 500;
  color: #888;
}

.scene-columns, .scene-row {
  display: grid;
  grid-template-columns: 
    40px                /* checkbox */
    50px               /* scene number */
    60px               /* day */
    80px               /* page length */
    minmax(250px, 2fr) /* description */
    80px               /* status */
    minmax(120px, 1fr) /* cast */
    100px              /* prestop */
    100px              /* duration */
    80px;              /* preview */
  gap: 12px;
  align-items: center;
  min-width: 1200px;
}

.scene-list {
  background: #1e1e1e;
  border-radius: 0 0 8px 8px;
}

.scene-row {
  padding: 16px;
  border-bottom: 1px solid #3e3e3e;
}

.scene-row:last-child {
  border-bottom: none;
}

.status span {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.status .active {
  background: rgba(41, 98, 255, 0.2);
  color: #2962ff;
}

.status .omitted {
  background: rgba(255, 71, 87, 0.2);
  color: #ff4757;
}

.setting-tag {
  background: #2c2c2c;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.preview-btn {
  background: transparent;
  border: 1px solid #3e3e3e;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preview-btn:hover {
  background: #3e3e3e;
}

/* Include the router-bar styles from ProjectOverview */
.router-bar {
  padding: 16px 24px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;
}

.breadcrumb-item {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.breadcrumb-item:hover {
  opacity: 1;
}

.breadcrumb-item.active {
  opacity: 1;
  font-weight: 500;
  cursor: default;
}

.separator {
  color: #888;
  margin: 0 4px;
}

.scene-description {
  position: relative;
  cursor: help;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
  padding: 8px 0;
}

.motif {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.synopsis {
  font-style: italic;
  color: #888;
  font-size: 12px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(20, 20, 20, 0.98);
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.5;
  width: 450px;
  z-index: 1000;
  white-space: normal;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: left;
  transition: opacity 0.1s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.scene-description:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Ensure the tooltip is not cut off */
.scene-list, 
.scene-content, 
.main-content {
  overflow: visible !important;
}

.properties, .cast {
  text-align: center;
}

.checkbox-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-column input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.scene-number {
  text-align: center;
  font-weight: 500;
}

.synopsis-tooltip {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background: #2c2c2c;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.4;
  max-width: 300px;
  z-index: 10;
  white-space: normal;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
}

.scene-description:hover .synopsis-tooltip {
  display: block;
}

/* Remove the old tooltip styles */
.scene-description:hover::after {
  display: none;
}

/* Update responsive styles for tooltip */
@media (max-width: 768px) {
  .synopsis-tooltip {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 480px) {
  .synopsis-tooltip {
    max-width: 200px;
    font-size: 11px;
    padding: 6px 8px;
  }
}

/* Responsive styles */
@media (max-width: 1400px) {
  .scene-columns, .scene-row {
    grid-template-columns: 
      40px 
      50px 
      60px  /* day */
      80px  /* page length */
      minmax(200px, 2fr) 
      80px 
      120px 
      100px /* prestop */
      100px 
      80px;
    gap: 8px;
  }

  .scene-content {
    max-width: calc(100% - 50px);
    padding: 16px;
  }
}

@media (max-width: 1200px) {
  .scene-columns, .scene-row {
    grid-template-columns: 
      40px 
      50px 
      60px  /* day */
      80px  /* page length */
      minmax(150px, 2fr) 
      80px 
      120px 
      100px /* prestop */
      100px;
    gap: 8px;
  }

  /* Hide preview column */
  .preview {
    display: none;
  }
}

@media (max-width: 1024px) {
  .scene-columns, .scene-row {
    grid-template-columns: 
      40px 
      50px 
      60px  /* day */
      80px  /* page length */
      minmax(150px, 2fr) 
      80px 
      120px;
    gap: 8px;
  }

  /* Hide prestop and duration columns */
  .prestop, .duration {
    display: none;
  }
}

@media (max-width: 900px) {
  .scene-columns, .scene-row {
    grid-template-columns: 
      40px 
      50px 
      60px  /* day */
      80px  /* page length */
      minmax(150px, 2fr) 
      80px;
    gap: 8px;
  }

  /* Hide cast column */
  .cast {
    display: none;
  }
}

@media (max-width: 768px) {
  .scene-columns, .scene-row {
    grid-template-columns: 
      40px 
      50px 
      60px  /* day */
      minmax(150px, 2fr) 
      80px;
    gap: 8px;
  }

  /* Hide page length column */
  .scene-pagelength {
    display: none;
  }
}

@media (max-width: 600px) {
  .scene-columns, .scene-row {
    grid-template-columns: 
      40px 
      50px 
      minmax(120px, 2fr) 
      80px;
    gap: 4px;
  }

  /* Hide day column */
  .scene-day {
    display: none;
  }
}

/* Add styles for the new columns */
.scene-day,
.scene-pagelength,
.prestop {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prestop .duration-tag,
.duration .duration-tag {
  min-width: 80px;
  text-align: center;
}

/* Update sortable columns style */
.sortable {
  cursor: pointer;
  user-select: none;
  color: #888;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: center;
  padding: 0 8px;
}

.sort-icon {
  font-size: 12px;
  opacity: 0.7;
}

.column-title {
  white-space: nowrap;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

.error-message {
  color: #ff4757;
  padding: 16px;
  margin: 16px;
  background: rgba(255, 71, 87, 0.1);
  border-radius: 4px;
  text-align: center;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.duration {
  text-align: center;
}

.duration-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background: rgba(41, 98, 255, 0.2);
  color: #2962ff;
  transition: all 0.2s ease;
}

.duration-tag.unset {
  background: rgba(255, 255, 255, 0.1);
  color: #888;
  cursor: pointer;
}

.duration-tag.unset:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

.column-filter {
  margin-top: 8px;
}

.prestop {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.column-filter input,
.column-filter select {
  width: 100%;
  padding: 4px 8px;
  background: #1e1e1e;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
}

.column-filter input::placeholder {
  color: #666;
}

.column-filter input:focus,
.column-filter select:focus {
  outline: none;
  border-color: #2962ff;
}

.scene-header {
  padding: 12px 16px 16px;
}

.scene-columns > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 4px;
}

.scene-description {
  justify-content: flex-start;
}

.sortable {
  cursor: pointer;
  user-select: none;
  color: #888;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: center;
}

.sort-icon {
  font-size: 12px;
  opacity: 0.7;
}

.column-title {
  white-space: nowrap;
  font-weight: 500;
}

.scene-row > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.scene-row .scene-description {
  justify-content: flex-start;
}

.edit-btn {
  background: transparent;
  border: 1px solid #3e3e3e;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-btn:hover {
  background: #3e3e3e;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #888;
  font-size: 14px;
}

.edit-input {
  width: 100%;
  padding: 8px;
  background: #1e1e1e;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
}

.edit-input:focus {
  outline: none;
  border-color: #2962ff;
}

.p-button.p-button-text {
  color: #888;
  padding: 0.5rem;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  &:focus {
    box-shadow: none;
  }

  .p-button-icon {
    font-size: 1rem;
  }
}

/* If you want to adjust the column width/alignment */
.p-datatable .p-datatable-tbody > tr > td:last-child {
  text-align: center;
  padding: 0.5rem;
}

textarea.edit-input {
  resize: vertical;
  min-height: 80px;
}

.tag-input {
  background: #1e1e1e;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  padding: 8px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.tag {
  background: #2c2c2c;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.remove-tag {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 0 4px;
}

.remove-tag:hover {
  color: #ff4757;
}

.add-tag {
  display: flex;
  gap: 8px;
}

.tag-input-field {
  flex: 1;
  background: #2c2c2c;
  border: none;
  padding: 4px 8px;
  color: #ffffff;
  border-radius: 4px;
}

.add-tag-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 12px 32px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.save-btn:disabled {
  background: #4a4a4a;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #1a1a1a;
  border-radius: 12px;
  width: 95%;
  max-width: 800px;
  margin: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
}

.modal-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: calc(90vh - 180px);
  overflow-y: auto;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;
}

.form-group.full-width {
  grid-column: 1 / -1;
}

.form-group label {
  color: #888;
  font-size: 14px;
  font-weight: 500;
}

.edit-input {
  width: 100%;
  padding: 12px;
  background: #2a2a2a;
  border: 1px solid #404040;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s ease;
}

.specials-row {
  padding: 16px 24px;
  border-top: 1px solid #333;
}

.special-icons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.special-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #2a2a2a;
  cursor: pointer;
  transition: all 0.2s ease;
}

.special-icon:hover {
  background: #333;
}

.special-icon.active {
  background: rgba(41, 98, 255, 0.2);
  color: #2962ff;
}

.special-icon svg {
  width: 24px;
  height: 24px;
}

textarea.edit-input {
  min-height: 100px;
  resize: vertical;
}

.modal-actions {
  padding: 24px;
  border-top: 1px solid #3e3e3e;
  margin-top: auto;
}

.save-btn {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border-radius: 8px;
  background: #2962ff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.save-btn:hover {
  background: #1e4fc4;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #333;
  margin-top: 10px;
}

.scene-identifier {
  display: flex;
  align-items: center;
  gap: 16px;
}

.scene-number {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.scene-meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.scene-day, .scene-pages {
  font-size: 14px;
  color: #888;
}

.form-section {
  padding: 24px;
  border-bottom: 1px solid #333;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 18px;
  margin-bottom: 20px;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #888;
  font-size: 14px;
  margin-bottom: 8px;
}

.edit-input {
  background: #2a2a2a;
  border: 1px solid #404040;
  color: #fff;
  transition: all 0.2s ease;
}

.edit-input:focus {
  border-color: #2962ff;
  box-shadow: 0 0 0 2px rgba(41, 98, 255, 0.1);
}

.tag {
  background: #2a2a2a;
  border: 1px solid #404040;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-tag-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
}

.modal-footer {
  padding: 20px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.cancel-btn {
  background: transparent;
  border: 1px solid #404040;
  color: #fff;
  padding: 10px 24px;
  border-radius: 6px;
  font-weight: 500;
}

.save-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 10px 32px;
  border-radius: 6px;
  font-weight: 500;
  min-width: 140px;
}

.save-btn:disabled {
  background: #404040;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255,255,255,0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

.scene-info-grid {
  background: #1a1a1a;
  border-bottom: 1px solid #333;
}

.top-row, .motif-row, .cast-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #333;
}

.left-section {
  display: flex;
  flex: 1;
}

.right-section {
  display: flex;
  gap: 24px;
  padding-right: 24px;
}

.scene-block,
.dn-block,
.day-block,
.pages-block,
.prestop-block,
.duration-block,
.motif-block,
.synopsis-block,
.cast-block,
.props-block,
.specials-block {
  padding: 16px 24px;
  position: relative;
}

.dn-block::before,
.day-block::before,
.pages-block::before,
.prestop-block::before,
.duration-block::before,
.motif-block::before,
.synopsis-block::before,
.cast-block::before,
.props-block::before,
.specials-block::before {
  content: '';
  position: absolute;
  left: 0;
  top: 12px;
  bottom: 12px;
  width: 1px;
  background: #333;
}

.motif-row {
  padding: 16px 0;
}

.motif-row .left-section {
  padding-left: 24px;
}

.motif-row .right-section {
  gap: 24px;
}

.status-block,
.shot-block {
  position: relative;
  padding: 0 24px;
}

.status-block::before,
.shot-block::before {
  content: '';
  position: absolute;
  left: 0;
  top: -4px;
  bottom: -4px;
  width: 1px;
  background: #333;
}

.label {
  font-size: 12px;
  color: #888;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.value {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.motif-row .value {
  font-size: 20px;
}

.right-section .value {
  font-size: 18px;
}

.cast-preview {
  position: relative;
  cursor: default;
}

.more-actors {
  color: #888;
  font-size: 0.9em;
  margin-left: 4px;
}

.actors-tooltip {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background: #2c2c2c;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.4;
  z-index: 10;
  white-space: normal;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  min-width: 200px;
  max-width: 300px;
}

.cast-preview:hover .actors-tooltip {
  display: block;
}

/* Override PrimeVue DataTable styles to force dark mode */
:deep(.p-datatable) {
  background: #1a1a1a !important;
  color: #ffffff !important;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  background: #1a1a1a !important;
  color: #888 !important;
  border: 1px solid #333 !important;
  border-width: 0 0 1px 0 !important;
}

:deep(.p-datatable .p-datatable-tbody > tr) {
  background: #1e1e1e !important;
  color: #ffffff !important;
}

:deep(.p-datatable .p-datatable-tbody > tr:hover) {
  background: #252525 !important;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  border: 1px solid #333 !important;
  border-width: 0 0 1px 0 !important;
}

:deep(.p-checkbox .p-checkbox-box) {
  background: #2a2a2a !important;
  border: 1px solid #404040 !important;
}

:deep(.p-checkbox .p-checkbox-box.p-highlight) {
  background: #2962ff !important;
  border-color: #2962ff !important;
}

:deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even)) {
  background: #252525 !important;
}

:deep(.p-column-header-content) {
  justify-content: center;
}

:deep(.p-datatable .p-sortable-column:not(.p-highlight):hover) {
  background: #252525 !important;
  color: #ffffff !important;
}

:deep(.p-datatable .p-sortable-column.p-highlight) {
  background: #1a1a1a !important;
  color: #2962ff !important;
}

:deep(.p-datatable .p-sortable-column.p-highlight:hover) {
  background: #252525 !important;
  color: #2962ff !important;
}

:deep(.p-datatable .p-sortable-column:focus) {
  box-shadow: inset 0 0 0 0.15rem rgba(41, 98, 255, 0.15) !important;
  outline: none !important;
}

/* Scrollbar styling */
:deep(.p-datatable-wrapper::-webkit-scrollbar) {
  width: 8px;
  height: 8px;
}

:deep(.p-datatable-wrapper::-webkit-scrollbar-track) {
  background: #1a1a1a;
}

:deep(.p-datatable-wrapper::-webkit-scrollbar-thumb) {
  background: #404040;
  border-radius: 4px;
}

:deep(.p-datatable-wrapper::-webkit-scrollbar-thumb:hover) {
  background: #4a4a4a;
}
</style>
