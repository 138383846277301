<template>
  <div class="dashboard">
    <!-- Sidebar -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar">
          <img v-if="profilePictureUrl" :src="profilePictureUrl" alt="Profile" />
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
        </div>
        <div class="user-name">{{ authStore.user.username }}</div>
        <button class="logout-btn" @click="handleLogout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <div class="nav-section">
        <h2>{{ $t('sidebar.projects') }}</h2>
        <div
          v-for="project in projects"
          :key="project.id"
          class="nav-item"
          :class="{ active: project.id === currentProjectId }"
          @click="navigateToProject(project.id)"
        >
          <i class="fas fa-film"></i>
          {{ project.name }}
        </div>
      </div>
      <div class="sidebar-footer">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Router Bar -->
      <div class="router-bar">
        <div class="breadcrumb">
          <span class="breadcrumb-item" @click="navigateToOrganization">{{ authStore.user.organization }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item" @click="navigateToProject">{{ projectName }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item active">{{ $t('shootingSchedule.title') }}</span>
        </div>
      </div>

      <!-- Content Header -->
      <div class="content-header">
        <div class="header-left">
          <h1>{{ $t('shootingSchedule.title') }}</h1>
        </div>
        <div class="header-right">
          <template v-if="!isEditorMode">
            <button 
              class="edit-mode-btn"
              @click="toggleEditorMode"
            >
              <i class="fas fa-edit"></i>
              {{ $t('shootingSchedule.editButton') }}
            </button>
          </template>
          <template v-else>
            <button 
              class="edit-mode-btn cancel-btn"
              @click="cancelEditing"
            >
              {{ $t('shootingSchedule.cancelButton') }}
            </button>
            <button 
              class="edit-mode-btn save-btn"
              @click="saveChanges"
              :disabled="isSaving"
            >
              {{ isSaving ? $t('shootingSchedule.saving') : $t('shootingSchedule.saveButton') }}
            </button>
          </template>
        </div>
      </div>

      <!-- Scene Content -->
      <div class="scene-content">
        <!-- Add this block before the Table Header -->
        <div v-if="!scheduleGroupedByDay.length" class="empty-schedule">
          <div class="empty-state">
            <i class="fas fa-calendar-plus empty-icon"></i>
            <h2>{{ $t('shootingSchedule.noSchedule') }}</h2>
            <p>{{ $t('shootingSchedule.setupSteps') }}</p>
            <ul class="setup-steps">
              <li>
                <input 
                  type="checkbox" 
                  v-model="setupChecklist.scriptBreakdown"
                  class="setup-checkbox"
                >
                <i class="fas fa-check-circle" :class="{ 'checked': setupChecklist.scriptBreakdown }"></i>
                <span>{{ $t('shootingSchedule.setupChecklist.scriptBreakdown') }}</span>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  v-model="setupChecklist.locations"
                  class="setup-checkbox"
                >
                <i class="fas fa-check-circle" :class="{ 'checked': setupChecklist.locations }"></i>
                <span>{{ $t('shootingSchedule.setupChecklist.location') }}</span>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  v-model="setupChecklist.locationUnavailabilities"
                  class="setup-checkbox"
                >
                <i class="fas fa-check-circle" :class="{ 'checked': setupChecklist.locationUnavailabilities }"></i>
                <span>{{ $t('shootingSchedule.setupChecklist.locationUnavailabilities') }}</span>
              </li>
              <li>
                <input 
                  type="checkbox" 
                  v-model="setupChecklist.castMembers"
                  class="setup-checkbox"
                >
                <i class="fas fa-check-circle" :class="{ 'checked': setupChecklist.castMembers }"></i>
                <span>{{ $t('shootingSchedule.setupChecklist.castUnavailabilities') }}</span>
              </li>
            </ul>
            <p class="terms-text">
              {{ $t('shootingSchedule.note') }}
            </p>
            <button 
              class="create-schedule-btn" 
              @click="createNewSchedule"
              :disabled="!isChecklistComplete"
            >
              <i class="fas fa-plus"></i>
              {{ $t('shootingSchedule.createScheduleButton') }}
            </button>
          </div>
        </div>

        <!-- Existing table header and content (wrap in v-else) -->
        <template v-else>
          <div class="scene-columns scene-header">
            <div class="checkbox-col"></div>
            <div class="scene-number">#</div>
            <div class="scene-description">{{ $t('shootingSchedule.sceneDescription') }}</div>
            <div class="properties">{{ $t('shootingSchedule.properties') }}</div>
            <div class="cast">{{ $t('shootingSchedule.cast') }}</div>
            <div class="location">{{ $t('shootingSchedule.location') }}</div>
            <div class="setting">{{ $t('shootingSchedule.setting') }}</div>
            <div class="time">{{ $t('shootingSchedule.time') }}</div>
          </div>

          <!-- Scene List -->
          <div class="scene-list">
            <draggable 
              v-model="scheduleGroupedByDay" 
              :disabled="!isEditorMode"
              item-key="dayNumber"
              handle=".day-header"
              @end="onDayOrderChanged"
            >
              <template #item="{ element: day }">
                <div class="day-group">
                  <div class="day-header" @click="toggleDay(day.dayNumber)">
                    <div class="day-info">
                      <span v-if="isEditorMode" class="drag-handle">⋮⋮</span>
                      <span class="day-number">{{ $t('shootingSchedule.day') }} {{ day.dayNumber }}</span>
                      <span class="daylight-time">{{ daylightTime }}</span>
                    </div>
                    <div class="expand-icon" :class="{ 'expanded': expandedDays.includes(day.dayNumber) }">
                      ▼
                    </div>
                  </div>

                  <div v-if="expandedDays.includes(day.dayNumber)" class="sequences">
                    <draggable 
                      v-model="day.sequences" 
                      :disabled="!isEditorMode"
                      item-key="_id"
                      group="sequences"
                      handle=".sequence-drag-handle"
                      @end="onSequenceOrderChanged"
                    >
                      <template #item="{ element: sequence }">
                        <div class="scene-row" :class="{ 'editor-mode': isEditorMode }">
                          <div class="checkbox-col">
                            <span v-if="isEditorMode" class="sequence-drag-handle">⋮⋮</span>
                            <input v-else type="checkbox" :checked="sequence.isComplete" disabled>
                          </div>
                          <div class="scene-number">{{ sequence.scene.scene_nr }}</div>
                          <div class="scene-description">
                            <div class="motif">{{ sequence.scene.motif }}</div>
                            <div class="synopsis">{{ sequence.scene.synopsis }}</div>
                            <div class="tooltip">{{ sequence.scene.synopsis }}</div>
                          </div>
                          <div class="properties">{{ sequence.scene.props.length }}</div>
                          <div class="cast">{{ sequence.scene.actors.join(', ') }}</div>
                          <div class="location">{{ sequence.scene.location }}</div>
                          <div class="setting">{{ sequence.scene.location_type }} / {{ sequence.scene.time_of_day }}</div>
                          <div 
                            class="time" 
                            :class="{ 'editable': isEditorMode }"
                            @click="isEditorMode && openTimeEditModal(sequence)"
                          >
                            {{ formatTimeRange(sequence.sequence_start, sequence.sequence_end) }}
                          </div>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </template>
      </div>
    </div>

    <!-- Time Edit Modal -->
    <div v-if="showTimeEditModal" class="modal-overlay" @click.self="closeTimeEditModal">
      <div class="modal">
        <div class="modal-header">
          <h2>Edit Scene Time</h2>
          <button class="close-btn" @click="closeTimeEditModal">&times;</button>
        </div>
        <div class="modal-content">
          <div class="time-inputs">
            <div class="form-group">
              <label>Start Time</label>
              <input 
                type="time" 
                class="time-input"
                v-model="editingTime.start"
              >
            </div>
            <div class="form-group">
              <label>End Time</label>
              <input 
                type="time" 
                class="time-input"
                v-model="editingTime.end"
              >
            </div>
          </div>
          <button 
            class="save-btn" 
            @click="saveSequenceTime"
            :disabled="isSaving"
          >
            {{ isSaving ? 'Saving...' : 'Save Changes' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import html2pdf from 'html2pdf.js'
import draggable from 'vuedraggable'
import axios from 'axios'
import { useAuthStore } from '../stores/auth'
export default {
  name: 'ShootingSchedule',
  components: {
    draggable
  },

  setup() {
    const authStore = useAuthStore()
    const router = useRouter()
    const currentProjectId = ref('')
    const profilePictureUrl = ref(null)
    const projects = ref([])
    const { locale } = useI18n()
    const projectName = computed(() => {
      const currentProject = projects.value.find(p => p.id === currentProjectId.value)
      return currentProject ? currentProject.name : ''
    })

    const schedule = ref([])
    const scheduleGroupedByDay = ref([])
    const daylightTime = ref('06:08 - 16:40')

    const isEditorMode = ref(false)
    const showTimeEditModal = ref(false)
    const editingSequence = ref(null)
    const editingTime = ref({ start: '', end: '' })
    const originalSchedule = ref(null) // To store original state
    const isSaving = ref(false)
    const isExporting = ref(false)
    const pdfContent = ref(null)
    const expandedDays = ref([])

    const setupChecklist = ref({
      scriptBreakdown: false,
      locations: false,
      castMembers: false
    })

    const isChecklistComplete = computed(() => {
      return Object.values(setupChecklist.value).every(value => value === true)
    })

    return {
      currentProjectId,
      profilePictureUrl,
      projectName,
      router,
      schedule,
      scheduleGroupedByDay,
      daylightTime,
      projects,
      isEditorMode,
      showTimeEditModal,
      editingSequence,
      editingTime,
      originalSchedule,
      isSaving,
      isExporting,
      pdfContent,
      expandedDays,
      setupChecklist,
      isChecklistComplete,
      authStore,
      locale
    }
  },

  methods: {
    async loadProjects() {
      try {
        const response = await axios.get(`/projects`, {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    async fetchSchedule() {
      try {
        const response = await axios.get(
          `/projects/${this.currentProjectId}/retrieve-schedule`,
          {
            withCredentials: true
          }
        )
        this.schedule = response.data
        this.scheduleGroupedByDay = this.groupByDay(response.data)
      } catch (error) {
        console.error('Error fetching schedule:', error)
        this.schedule = []
        this.scheduleGroupedByDay = []
      }
    },

    async createNewSchedule() {
      try {
        await axios.post(
          `/projects/${this.currentProjectId}/create-schedule`,
          {},
          {
            withCredentials: true
          }
        )
        // Refresh the schedule data
        await this.fetchSchedule()
      } catch (error) {
        console.error('Error creating schedule:', error)
        // You might want to show an error notification here
      }
    },

    groupByDay(schedule) {
      const grouped = schedule.reduce((acc, item) => {
        const day = item.sequence_day
        if (!acc[day]) {
          acc[day] = {
            dayNumber: day,
            sequences: []
          }
        }
        acc[day].sequences.push(item)
        return acc
      }, {})

      return Object.values(grouped).sort((a, b) => a.dayNumber - b.dayNumber)
    },

    formatTime(dateTime) {
      const date = new Date(dateTime)
      return date.toISOString().substr(11, 5)
    },

    navigateToProject() {
      this.router.push(`/project/${this.currentProjectId}`)
    },

    navigateToOrganization() {
      this.router.push('/dashboard')
    },

    handleLogout() {
      this.router.push('/login')
    },

    toggleEditorMode() {
      if (!this.isEditorMode) {
        // Store original state when entering edit mode
        this.originalSchedule = JSON.parse(JSON.stringify(this.scheduleGroupedByDay))
        this.isEditorMode = true
      }
    },

    onDayOrderChanged() {
      // Handle day order changes
      console.log('Day order changed')
    },

    onSequenceOrderChanged() {
      // Handle sequence order changes
      console.log('Sequence order changed')
    },

    async updateSequenceTime(sequenceId, startTime, endTime) {
      const response = await axios.put(
        `/projects/${this.currentProjectId}/sequences/${sequenceId}/update-time`,
        {
          startTime: startTime,
          endTime: endTime
        },
        {
          withCredentials: true
        }
      )

      return response.data
    },

    async saveSequenceTime() {
      if (this.isSaving) return
      
      this.isSaving = true
      try {
        await this.updateSequenceTime(
          this.editingSequence._id,
          this.editingTime.start,
          this.editingTime.end
        )

        await this.fetchSchedule() 
        this.closeTimeEditModal()
      } catch (error) {
        console.error('Error saving time:', error)
        alert('Failed to update sequence time')
      } finally {
        this.isSaving = false
      }
    },

    async saveChanges() {
      if (this.isSaving) return
      
      this.isSaving = true
      try {
        await this.updateScheduleOrder()
        this.isEditorMode = false
        this.originalSchedule = null
      } catch (error) {
        console.error('Error saving changes:', error)
      } finally {
        this.isSaving = false
      }
    },

    openTimeEditModal(sequence) {
      this.editingSequence = sequence
      this.editingTime = {
        start: this.formatTime(sequence.sequence_start),
        end: this.formatTime(sequence.sequence_end)
      }
      this.showTimeEditModal = true
    },

    closeTimeEditModal() {
      this.showTimeEditModal = false
      this.editingSequence = null
      this.editingTime = { start: '', end: '' }
    },

    async exportToPDF() {
      if (this.isExporting) return
      
      this.isExporting = true
      try {
        const element = this.$refs.pdfContent
        const opt = {
          margin: 0,
          filename: 'shooting-schedule.pdf',
          image: { type: 'jpeg', quality: 1 },
          html2canvas: { 
            scale: 2,
            useCORS: true,
            letterRendering: true,
            backgroundColor: '#1e1e1e',
            width: 794, // A4 width in pixels
            height: 1123, // A4 height in pixels
          },
          jsPDF: { 
            unit: 'mm', 
            format: 'a4', 
            orientation: 'portrait',
            compress: true,
            putOnlyUsedFonts: true,
            background: '#1e1e1e'
          },
          pagebreak: {
            mode: ['avoid-all', 'css', 'legacy'],
            after: '.day-schedule'
          }
        }

        await html2pdf()
          .set(opt)
          .from(element)
          .save()

      } catch (error) {
        console.error('Error exporting PDF:', error)
      } finally {
        this.isExporting = false
      }
    },

    formatTimeRange(start, end) {
      if (!start || !end) return ''
      return `${this.formatTime(start)} - ${this.formatTime(end)}`
    },

    toggleDay(dayNumber) {
      const index = this.expandedDays.indexOf(dayNumber)
      if (index === -1) {
        this.expandedDays.push(dayNumber)
      } else {
        this.expandedDays.splice(index, 1)
      }
    },

    async updateScheduleOrder() {
      // First update day order
      await axios.put(
        `/projects/${this.currentProjectId}/update-schedule-order`,
        {
          type: 'day',
          order: this.scheduleGroupedByDay.map((day, index) => ({
            dayNumber: day.dayNumber,
            newOrder: index + 1
          }))
        },
        {
          withCredentials: true
        }
      )

      // Then update sequence order for each day
      for (const day of this.scheduleGroupedByDay) {
        await axios.put(
          `/projects/${this.currentProjectId}/update-schedule-order`,
          {
            type: 'sequence',
            dayNumber: day.dayNumber,
            order: day.sequences.map((sequence, index) => ({
              sequenceId: sequence._id,
              newOrder: index + 1
            }))
          },
          {
            withCredentials: true
          }
        )
      }

      return true
    },

    cancelEditing() {
      // Restore original state
      this.scheduleGroupedByDay = JSON.parse(JSON.stringify(this.originalSchedule))
      this.isEditorMode = false
      this.originalSchedule = null
    }
  },

  mounted() {
    this.currentProjectId = this.$route.params.projectId
    this.loadProjects()
    this.fetchSchedule()
  }
}
</script>

<style scoped>
.dashboard {
  display: flex;
  min-height: 100vh;
  background: #1e1e1e;
  color: #ffffff;
  overflow-x: hidden;
  position: relative;
}

.sidebar {
  width: 200px;
  background: #2c2c2c;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #3e3e3e;
  position: sticky;
  top: 0;
  height: auto;
  overflow-y: auto;
}

.user-section {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  margin-bottom: 24px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  background: #3e3e3e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.user-name {
  flex: 1;
  font-size: 14px;
  color: #ffffff;
}

.logout-btn {
  background: transparent;
  border: none;
  color: #888;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.logout-btn:hover {
  color: #ffffff;
  background: #363636;
}

.nav-section h2 {
  font-size: 12px;
  text-transform: uppercase;
  color: #888;
  margin-bottom: 8px;
  padding: 0 12px;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 8px;
}

.nav-item:hover {
  background: #363636;
}

.nav-item.active {
  background: #363636;
  font-weight: 500;
}

.main-content {
  flex: 1;
  position: relative;
  min-width: 0;
  padding: 0 24px 24px;
}

.router-bar {
  padding: 16px 24px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;
}

.breadcrumb-item {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.breadcrumb-item:hover {
  opacity: 1;
}

.breadcrumb-item.active {
  opacity: 1;
  font-weight: 500;
  cursor: default;
}

.separator {
  color: #888;
  margin: 0 4px;
}

.content-header {
  margin-left: 75px;
  padding: 0 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-left h1 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.header-right {
  display: flex;
  gap: 16px;
  margin-right: 24px;
}

.edit-mode-btn,
.save-changes-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-mode-btn {
  background: #2c2c2c;
  border: 1px solid #3e3e3e;
}

.edit-mode-btn.active {
  background: #363636;
  border-color: #3e3e3e;
}

.save-changes-btn {
  background: #2962ff;
  border: 1px solid #2962ff;
}

.save-changes-btn:hover {
  background: #2151d5;
}

.edit-mode-btn:hover {
  background: #363636;
}

/* Your existing schedule-specific styles */
.schedule-content {
  margin-left: 75px;
  padding: 24px;
}

.project-selector {
  margin-bottom: 20px;
}

.project-selector select {
  padding: 5px;
  border-radius: 5px;
}

.schedule-header {
  display: flex;
  justify-content: space-between;
  background-color: #444;
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

.schedule-list {
  margin-top: 10px;
}

.sequence {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}

.sequence-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
}

.scene-number {
  font-weight: bold;
}

.sequence-body {
  padding: 10px;
  background-color: #f9f9f9;
}

.no-schedule-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #666;
}

.day-schedule {
  background: #2c2c2c;
  border-radius: 8px;
  margin-bottom: 24px;
  overflow: hidden;
}

.day-header {
  background: #363636;
  padding: 16px;
  border-bottom: 1px solid #3e3e3e;
}

.day-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day-number {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.daylight-time {
  color: #888;
  font-size: 14px;
}

.sequences {
  padding: 16px;
}

.sequence-card {
  background: #1e1e1e;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
}

.sequence-card:last-child {
  margin-bottom: 0;
}

.sequence-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.scene-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.scene-number {
  font-weight: 500;
  color: #ffffff;
}

.scene-type {
  color: #888;
  font-size: 14px;
}

.sequence-time {
  background: rgba(41, 98, 255, 0.1);
  color: #2962ff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.sequence-location {
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 8px;
}

.sequence-synopsis {
  color: #888;
  font-size: 14px;
  line-height: 1.4;
}

.no-schedule {
  text-align: center;
  color: #888;
  padding: 48px;
  background: #2c2c2c;
  border-radius: 8px;
}

.drag-handle, 
.sequence-drag-handle {
  cursor: move;
  padding: 0 8px;
  color: #666;
  user-select: none;
}

.drag-handle:hover, 
.sequence-drag-handle:hover {
  color: #fff;
}

.sequence-time.editable {
  cursor: pointer;
}

.sequence-time.editable:hover {
  background: rgba(41, 98, 255, 0.2);
}

.sequence-card.editor-mode {
  border: 1px solid transparent;
}

.sequence-card.editor-mode:hover {
  border-color: #2962ff;
}

.save-changes-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #2c2c2c;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid #3e3e3e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  color: #ffffff;
}

.close-btn {
  background: transparent;
  border: none;
  color: #888;
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
}

.close-btn:hover {
  color: #ffffff;
}

.modal-content {
  padding: 24px;
}

.time-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: #888;
  font-size: 14px;
}

.time-input {
  width: 100%;
  padding: 8px;
  background: #1e1e1e;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
}

.save-btn {
  width: 100%;
  padding: 12px;
  background: #2962ff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.2s ease;
}

.save-btn:hover {
  background: #2151d5;
}

.save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Add this to make time slots look clickable in edit mode */
.sequence-time.editable {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.sequence-time.editable:hover {
  background: rgba(41, 98, 255, 0.2);
}

.export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #2c2c2c;
  border: 1px solid #3e3e3e;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.export-btn:hover {
  background: #363636;
}

.export-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* PDF-specific styles */
.pdf-content {
  display: none;
}

.pdf-content.for-pdf {
  display: block;
  background: #1e1e1e;
  color: #ffffff;
  padding: 40px;
}

.for-pdf .day-schedule {
  background: #2c2c2c;
  border-radius: 8px;
  margin-bottom: 24px;
  overflow: hidden;
  break-inside: avoid;
  page-break-after: always;
}

.for-pdf .day-schedule:last-child {
  page-break-after: avoid;
}

.for-pdf .schedule {
  background: #1e1e1e;
}

.scene-content {
  max-width: 1600px;
  margin: 0 24px;
  padding: 24px;
  max-width: 100%;
  overflow-x: auto;
  background: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
}

.day-group {
  margin-bottom: 1px;
  background: #1e1e1e;
}

.day-group:last-child {
  margin-bottom: 0;
}

.day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #2c2c2c;
  cursor: pointer;
  user-select: none;
}

.sequences {
  background: #1e1e1e;
}

.scene-columns {
  display: grid;
  grid-template-columns: 
    40px 
    60px 
    500px
    80px 
    200px 
    150px 
    140px 
    100px 
    120px;
  gap: 8px;
  padding: 12px 24px;
  align-items: left;
}

.scene-description {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scene-description .motif {
  font-weight: 500;
  margin-bottom: 4px;
}

.scene-description .synopsis {
  color: #888;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scene-number {
  text-align: center;
}

.properties {
  text-align: center;
}

.cast,
.location,
.setting,
.time {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Column headers */
.scene-header {
  background: #2c2c2c;
  font-size: 12px;
  text-transform: uppercase;
  color: #888;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.scene-header > div {
  padding: 8px 4px;
}

.time {
  min-width: 140px;
  text-align: right;
  padding-right: 8px;
}

.scene-description {
  text-align: left;
  padding-right: 8px;
}

.scene-number,
.properties {
  text-align: center;
}

.cast,
.location,
.setting {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
}

.scene-row {
  display: grid;
  grid-template-columns: 
    40px 
    60px 
    500px
    80px 
    200px 
    150px 
    140px 
    100px 
    120px;
  align-items: center;
  padding: 0 16px;
  min-height: 64px;
  border-bottom: 1px solid #2c2c2c;
}

.scene-row:hover {
  background: rgba(255, 255, 255, 0.02);
}

.scene-description {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 0;
}

.motif {
  font-weight: 500;
  color: #fff;
}

.synopsis {
  font-size: 13px;
  color: #888;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: help;
}

.day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #2c2c2c;
  cursor: pointer;
  user-select: none;
}

.day-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.day-number {
  font-weight: 500;
}

.daylight-time {
  color: #888;
  font-size: 13px;
}

.expand-icon {
  font-size: 12px;
  transition: transform 0.2s ease;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.active {
  background: rgba(41, 98, 255, 0.2);
  color: #2962ff;
}

.checkbox-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-col input {
  width: 16px;
  height: 16px;
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 4px;
  background: rgba(20, 20, 20, 0.98);
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.5;
  width: 450px;
  z-index: 1000;
  white-space: normal;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: left;
  transition: opacity 0.1s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.scene-description:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Ensure proper text alignment and overflow handling */
.scene-number,
.properties,
.time {
  text-align: center;
}

.cast,
.location,
.setting {
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ensure text alignment matches screenshot */
.scene-number,
.properties {
  text-align: center;
}

.status {
  display: flex;
  justify-content: center;
}

.cast,
.location,
.setting,
.time {
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scene-description {
  padding-right: 24px;
}

.edit-mode-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  background: #2c2c2c;
  border: 1px solid #3e3e3e;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  min-width: 120px;
  justify-content: center;
}

.edit-mode-btn:hover {
  background: #363636;
}

.cancel-btn {
  background: #2c2c2c;
  border: 1px solid #3e3e3e;
}

.cancel-btn:hover {
  background: #363636;
}

.save-btn {
  background: #2962ff;
  border: 1px solid #2962ff;
}

.save-btn:hover {
  background: #2151d5;
}

.save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background: #2962ff;
}

.drag-handle,
.sequence-drag-handle {
  cursor: move;
  padding: 0 8px;
  color: #666;
  user-select: none;
}

.drag-handle:hover,
.sequence-drag-handle:hover {
  color: #fff;
}

.time.editable {
  cursor: pointer;
}

.time.editable:hover {
  color: #2962ff;
}

.scene-row.editor-mode {
  border: 1px solid transparent;
}

.scene-row.editor-mode:hover {
  border-color: #2962ff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #1e1e1e;
  border-radius: 8px;
  min-width: 300px;
  max-width: 90%;
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid #3e3e3e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  color: #ffffff;
}

.close-btn {
  background: transparent;
  border: none;
  color: #888;
  font-size: 24px;
  cursor: pointer;
}

.close-btn:hover {
  color: #ffffff;
}

.modal-content {
  padding: 24px;
}

.time-inputs {
  margin-bottom: 24px;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #888;
}

.time-input {
  width: 100%;
  padding: 8px;
  background: #2c2c2c;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
}

.save-btn {
  width: 100%;
  padding: 12px;
  background: #2962ff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.2s ease;
}

.save-btn:hover {
  background: #2151d5;
}

.save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.empty-schedule {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  background: #2c2c2c;
  border-radius: 8px;
}

.empty-state {
  text-align: center;
  padding: 48px;
  max-width: 480px;
}

.empty-icon {
  font-size: 48px;
  color: #2962ff;
  margin-bottom: 24px;
}

.empty-state h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #ffffff;
}

.empty-state p {
  color: #888;
  margin-bottom: 24px;
}

.setup-steps {
  list-style: none;
  padding: 0;
  margin: 0 0 32px;
  text-align: left;
}

.setup-steps li {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #363636;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
}

.setup-steps li:last-child {
  margin-bottom: 0;
}

.setup-steps i {
  color: #2962ff;
}

.create-schedule-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: #2962ff;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.create-schedule-btn:hover {
  background: #2151d5;
}

.setup-steps li {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #363636;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
}

.setup-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #2962ff;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background: #2c2c2c;
}

.setup-checkbox:checked {
  background: #2962ff;
}

.setup-checkbox:checked::after {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fas.fa-check-circle {
  opacity: 0.3;
  transition: opacity 0.2s ease;
}

.fas.fa-check-circle.checked {
  opacity: 1;
  color: #2962ff;
}

.create-schedule-btn:disabled {
  background: #363636;
  cursor: not-allowed;
  opacity: 0.7;
}

.setup-steps li:hover {
  background: #404040;
}

.terms-text {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

.terms-text a {
  color: #4285f4;
  text-decoration: none;
}
</style>
