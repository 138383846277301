<template>
  <div class="dashboard">
    <!-- Left Sidebar (keeping the same structure) -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar">
          <img 
            v-if="profilePictureUrl" 
            :src="profilePictureUrl" 
            alt="Profile" 
            class="avatar-image"
          >
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
        </div>
        <span class="user-name">{{ authStore.user.username }}</span>
        <button class="logout-btn" @click="handleLogout" title="Logout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <!-- Keep the same nav-menu structure -->
      <nav class="nav-menu">
        <div class="nav-section">
          <h2>Projects</h2>
          <div 
            v-for="project in projects" 
            :key="project.id" 
            class="nav-item"
            :class="{ active: project.id === currentProjectId }"
            @click="navigateToProjectFromSidebar(project.id)"
          >
            <span>🎬</span>
            {{ project.name }}
          </div>
        </div>
      </nav>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Breadcrumb Navigation -->
      <div class="router-bar">
        <div class="breadcrumb">
          <span class="breadcrumb-item" @click="navigateToOrganization">{{ authStore.user.organization }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item" @click="navigateToProject">{{ projectName }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item active">Contacts</span>
        </div>
      </div>

      <div class="content-header">
        <div class="header-left">
          <h1>Contacts</h1>
        </div>
        <div class="header-right">
          <div class="new-contact-btn">
            <button class="new-contact-btn" @click="openNewContactModal">
              <span class="icon">+</span>
              New Contact
            </button>
          </div>
        </div>
      </div>

      <!-- Add View Switcher -->
      <div class="view-switcher">
        <button 
          v-for="view in views" 
          :key="view.id"
          :class="['view-btn', { active: currentView === view.id }]"
          @click="switchView(view.id)"
        >
          {{ view.label }}
        </button>
      </div>

      <!-- Crew Table -->
      <div class="crew-content">
        <div class="crew-header">
          <div class="crew-columns">
            <div class="checkbox-column">
              <input type="checkbox" @change="toggleAllContacts">
            </div>
            <div class="member-number">#</div>
            <div class="first-name sortable" @click="toggleSort('firstName')">
              <span class="column-title">First Name</span>
              <span class="sort-icon" v-if="sortField === 'firstName'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="last-name sortable" @click="toggleSort('lastName')">
              <span class="column-title">Last Name</span>
              <span class="sort-icon" v-if="sortField === 'lastName'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="category sortable" @click="toggleSort('category')">
              <span class="column-title">Category</span>
              <span class="sort-icon" v-if="sortField === 'category'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="department sortable" @click="toggleSort('department')">
              <span class="column-title">Department</span>
              <span class="sort-icon" v-if="sortField === 'department'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="title sortable" @click="toggleSort('title')">
              <span class="column-title">Title</span>
              <span class="sort-icon" v-if="sortField === 'title'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="phone">Phone</div>
            <div class="email">Email</div>
            <div class="location sortable" @click="toggleSort('location')">
              <span class="column-title">Location</span>
              <span class="sort-icon" v-if="sortField === 'location'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="date-joined sortable" @click="toggleSort('dateJoined')">
              <span class="column-title">Date Joined</span>
              <span class="sort-icon" v-if="sortField === 'dateJoined'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
            <div class="date-blocker sortable" @click="toggleSort('dateBlocker')">
              <span class="column-title">Date Blocker</span>
              <span class="sort-icon" v-if="sortField === 'dateBlocker'">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
            </div>
          </div>
        </div>

        <div class="crew-list">
          <div v-for="(contact, index) in sortedContacts" :key="contact.id" class="crew-row">
            <div class="checkbox-column">
              <input type="checkbox" v-model="contact.selected">
            </div>
            <div class="member-number">{{ index + 1 }}</div>
            <div class="first-name">{{ contact.first_name }}</div>
            <div class="last-name">{{ contact.last_name }}</div>
            <div class="category">
              <span class="category-tag">{{ contact.category }}</span>
            </div>
            <div class="department">{{ contact.department }}</div>
            <div class="title">{{ contact.title }}</div>
            <div class="phone">{{ contact.phone }}</div>
            <div class="email">{{ contact.email }}</div>
            <div class="location">{{ contact.location }}</div>
            <div class="date-joined">{{ formatDate(contact.date_joined) }}</div>
            <div class="date-blocker">
              <span class="blocker-tag" :class="contact.date_blocked ? 'has-blockers' : 'available'">
                {{ contact.date_blocked ? 'Blockers' : 'Available' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add New Contact Modal -->
  <div v-if="showNewContactModal" class="modal-overlay">
    <div class="modal">
      <div class="modal-header">
        <h2>Add New Contact</h2>
        <button class="close-btn" @click="closeNewContactModal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <div class="modal-content">
        <div class="form-row">
          <div class="form-group">
            <label>First Name*</label>
            <input 
              type="text" 
              v-model="newContact.first_name"
              class="edit-input"
              placeholder="Enter first name..."
              required
            >
          </div>

          <div class="form-group">
            <label>Last Name*</label>
            <input 
              type="text" 
              v-model="newContact.last_name"
              class="edit-input"
              placeholder="Enter last name..."
              required
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Category*</label>
            <select v-model="newContact.category" class="edit-input" required>
              <option value="cast">Cast</option>
              <option value="crew">Crew</option>
              <option value="extras">Extras</option>
              <option value="externals">Externals</option>
            </select>
          </div>

          <div class="form-group">
            <label>Department*</label>
            <input 
              type="text" 
              v-model="newContact.department"
              class="edit-input"
              placeholder="Enter department..."
              required
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Title*</label>
            <input 
              type="text" 
              v-model="newContact.title"
              class="edit-input"
              placeholder="Enter title..."
              required
            >
          </div>

          <div class="form-group">
            <label>Phone</label>
            <input 
              type="tel" 
              v-model="newContact.phone"
              class="edit-input"
              placeholder="Enter phone number..."
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Email*</label>
            <input 
              type="email" 
              v-model="newContact.email"
              class="edit-input"
              placeholder="Enter email..."
              required
            >
          </div>

          <div class="form-group">
            <label>Location</label>
            <input 
              type="text" 
              v-model="newContact.location"
              class="edit-input"
              placeholder="Enter location..."
            >
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label>Date Joined*</label>
            <input 
              type="date" 
              v-model="newContact.date_joined"
              class="edit-input"
              required
            >
          </div>

          <div class="form-group">
            <label>Date Blocked</label>
            <input 
              type="date" 
              v-model="newContact.date_blocked"
              class="edit-input"
            >
          </div>
        </div>

        <div class="form-group full-width">
          <label>Notes</label>
          <textarea 
            v-model="newContact.notes"
            class="edit-input"
            rows="4"
            placeholder="Enter any additional notes..."
          ></textarea>
        </div>

        <div class="modal-actions">
          <button 
            class="save-btn" 
            @click="saveNewContact"
            :disabled="isSaving || !isFormValid"
          >
            <span v-if="isSaving" class="spinner"></span>
            <span v-else>Save Contact</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Add loading state -->
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>

  <!-- Add error message -->
  <div v-if="error" class="error-message">
    {{ error }}
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { orderBy } from 'lodash-es'
import { useAuthStore } from '../stores/auth'
import axios from 'axios'

export default {
  name: 'ProjectContacts',

  setup() {
    const currentProjectId = ref('')
    const profilePictureUrl = ref(null)
    const projects = ref([])
    const authStore = useAuthStore()
    const contacts = ref([])
    const loading = ref(false)
    const error = ref(null)
    const showNewContactModal = ref(false)
    const isSaving = ref(false)
    const newContact = ref({
      first_name: '',
      last_name: '',
      category: '',
      department: '',
      title: '',
      phone: '',
      email: '',
      location: '',
      date_joined: '',
      notes: '',
      date_blocked: null
    })
    const sortField = ref(null)
    const sortOrder = ref('asc')
    const currentView = ref('all')
    const views = ref([
      { id: 'all', label: 'ALL' },
      { id: 'cast', label: 'CAST' },
      { id: 'crew', label: 'CREW' },
      { id: 'extras', label: 'EXTRAS' },
      { id: 'externals', label: 'EXTERNALS' }
    ])

    const projectName = computed(() => {
      const currentProject = projects.value.find(p => p.id === currentProjectId.value)
      return currentProject ? currentProject.name : ''
    })

    return {
      currentProjectId,
      profilePictureUrl,
      projects,
      contacts,
      projectName,
      loading,
      error,
      showNewContactModal,
      isSaving,
      newContact,
      sortField,
      sortOrder,
      currentView,
      views,
      authStore
    }
  },

  methods: {
    async loadContacts() {
      this.loading = true
      this.error = null
      
      try {
        const response = await axios.get(
          `/projects/${this.currentProjectId}/contacts`,
          {
            withCredentials: true
          }
        )
        this.contacts = response.data.contacts || []
      } catch (err) {
        console.error('Error loading contacts:', err)
        this.error = err.response?.data?.message || 'Failed to load contacts. Please try again.'
      } finally {
        this.loading = false
      }
    },

    async loadProjects() {
      try {
        const response = await axios.get('/projects', {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    openNewContactModal() {
      this.showNewContactModal = true
    },

    closeNewContactModal() {
      this.showNewContactModal = false
      Object.keys(this.newContact).forEach(key => {
        this.newContact[key] = key === 'date_blocked' ? null : ''
      })
    },

    async saveNewContact() {
      if (!this.isFormValid) return

      this.isSaving = true
      try {
        await axios.post(
          `/projects/${this.currentProjectId}/contacts`,
          {
            project_id: this.currentProjectId,
            ...this.newContact,
            date_joined: new Date(this.newContact.date_joined).toISOString(),
            date_blocked: this.newContact.date_blocked ? 
              new Date(this.newContact.date_blocked).toISOString() : null
          },
          {
            withCredentials: true
          }
        )

        await this.loadContacts()
        this.closeNewContactModal()
      } catch (error) {
        console.error('Error creating contact:', error)
        alert(error.response?.data?.message || 'Failed to create contact. Please try again.')
      } finally {
        this.isSaving = false
      }
    },

    switchView(viewId) {
      this.currentView = viewId
    },

    toggleSort(field) {
      if (this.sortField === field) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortField = field
        this.sortOrder = 'asc'
      }
    },

    formatDate(dateString) {
      if (!dateString) return '';
      try {
        return new Date(dateString).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      } catch (error) {
        console.error('Error formatting date:', error);
        return '';
      }
    },

    navigateToProject() {
      this.$router.push(`/project/${this.currentProjectId}`)
    },

    navigateToOrganization() {
      this.$router.push('/dashboard')
    },

    async handleLogout() {
      try {
        await axios.post('/user/v1/logout', {}, {
          withCredentials: true
        })
        this.$router.push('/login')
      } catch (error) {
        console.error('Logout failed:', error)
        // Still redirect to login even if the logout request fails
        this.$router.push('/login')
      }
    },

    async navigateToProjectFromSidebar(projectId) {
      try {
        const response = await axios.get(`/projects/${projectId}`, {
          withCredentials: true
        })
        if (response.data) {
          this.$router.push(`/project/${projectId}`)
        }
      } catch (error) {
        console.error('Failed to navigate to project:', error)
      }
    }
  },

  computed: {
    isFormValid() {
      const required = [
        'first_name',
        'last_name',
        'category',
        'department',
        'title',
        'email',
        'date_joined'
      ]
      return required.every(field => this.newContact[field])
    },

    sortedContacts() {
      let filtered = Array.isArray(this.contacts) ? this.contacts : []
      
      // Filter by category
      if (this.currentView !== 'all') {
        filtered = filtered.filter(contact => 
          contact.category?.toLowerCase() === this.currentView
        )
      }

      if (!this.sortField) return filtered
      return orderBy(filtered, [this.sortField], [this.sortOrder])
    }
  },

  mounted() {
    this.currentProjectId = this.$route.params.projectId
    this.loadProjects()
    this.loadContacts()
  }
}
</script>

<style scoped>
.main-content {
  flex: 1;
  position: relative;
  min-width: 0;
}

.crew-content {
  margin: 0 24px;
  padding: 24px;
  max-width: 100%;
  overflow-x: auto;
  font-size: 13px;
}

.crew-columns, .crew-row {
  display: grid;
  grid-template-columns: 
    minmax(30px, 30px)       /* checkbox - reduced */
    minmax(40px, 40px)       /* ID number - reduced */
    minmax(90px, 100px)      /* First Name - reduced */
    minmax(90px, 100px)      /* Last Name - reduced */
    minmax(70px, 80px)       /* Category - reduced */
    minmax(90px, 100px)      /* Department - reduced */
    minmax(90px, 100px)      /* Title - reduced */
    minmax(90px, 100px)      /* Phone - reduced */
    minmax(130px, 150px)     /* Email - reduced */
    minmax(90px, 100px)      /* Location - reduced */
    minmax(80px, 90px)       /* Date Joined - reduced */
    minmax(80px, 90px);      /* Date Blocker - reduced */
  gap: 8px;
  align-items: center;
  min-width: 1000px;
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .crew-content {
    font-size: 14px;
  }
  
  .crew-columns, .crew-row {
    min-width: auto;
    gap: 12px;
  }
  
  .category-tag, .blocker-tag {
    padding: 4px 8px;
    font-size: 12px;
  }
}

.crew-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #2c2c2c;
  padding: 8px 12px;
}

.crew-row {
  padding: 8px 12px;
}

.category-tag {
  background: rgba(41, 98, 255, 0.2);
  color: #2962ff;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
}

.blocker-tag {
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
}

.blocker-tag.available {
  background: rgba(39, 174, 96, 0.2);
  color: #27ae60;
}

.blocker-tag.has-blockers {
  background: rgba(255, 159, 67, 0.2);
  color: #ff9f43;
}

.sortable {
  cursor: pointer;
  user-select: none;
}

.router-bar {
  padding: 16px 24px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;
}

.breadcrumb-item {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.breadcrumb-item:hover {
  opacity: 1;
}

.breadcrumb-item.active {
  opacity: 1;
  font-weight: 500;
  cursor: default;
}

.view-switcher {
  display: flex;
  gap: 8px;
  padding: 0 24px;
  margin: 16px 24px 8px;
}

.view-btn {
  padding: 6px 12px;
  border: 1px solid #3e3e3e;
  background: transparent;
  color: #888;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-btn:hover {
  background: #2c2c2c;
  color: #fff;
}

.view-btn.active {
  background: #2962ff;
  color: white;
  border-color: #2962ff;
}

/* Make sure the table header stays consistent */
.crew-content {
  margin-top: 8px;
}

.header-right {
  display: flex;
  gap: 16px;
}

.new-contact-btn {
  background: #2962ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-contact-btn:hover {
  background: #1e4fc4;
}

.new-contact-btn .icon {
  font-size: 18px;
  font-weight: bold;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

/* Modal styles can be reused from your existing styles */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2962ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-message {
  background: rgba(255, 71, 87, 0.2);
  color: #ff4757;
  padding: 12px 24px;
  border-radius: 4px;
  margin: 16px 24px;
  text-align: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #2c2c2c;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  padding: 0;
  max-height: 85vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #3e3e3e;
  position: relative;
  margin-bottom: 0;
}

.modal-header h2 {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
}

.close-btn {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.close-btn:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.modal-content {
  padding: 24px;
}

.form-row {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.form-group {
  flex: 1;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #888;
  font-size: 14px;
}

.edit-input, select {
  width: 100%;
  padding: 10px 12px;
  background: #1e1e1e;  /* Dark background */
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
}

.edit-input:focus, select:focus {
  border-color: #2962ff;
  outline: none;
}

textarea.edit-input {
  min-height: 100px;
  resize: vertical;
}

.modal-actions {
  margin-top: 24px;
  display: flex;
  justify-content: center;  /* Center the save button */
}

.save-btn {
  background: #2962ff;  /* Blue background */
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 120px;  /* Ensure minimum width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-btn:hover {
  background: #1e4fc4;  /* Darker blue on hover */
}

.save-btn:disabled {
  background: #4a4a4a;
  cursor: not-allowed;
}

/* Style for the date inputs */
input[type="date"] {
  background: #1e1e1e;
  color: #ffffff;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  padding: 10px 12px;
}

/* Style for the select dropdown */
select {
  appearance: none;  /* Remove default styling */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 36px;
}
</style>