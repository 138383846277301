<template>
  <div class="login-page">
    <div class="top-bar">
      <div class="logo">
        <div class="logo-circle">
          <h1>FW</h1>
        </div>
        <span>FastWrap</span>
      </div>
      <div class="language-selector">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <div class="content">
      <div class="login-container">
        <h1>{{ $t('login.title') }}</h1>
        <h2>{{ $t('login.subtitle') }}</h2>
        
        <form @submit.prevent="handleLogin">
          <div class="form-group">
            <label for="email">{{ $t('login.email') }}</label>
            <div class="input-wrapper">
              <span class="input-icon">✉️</span>
              <input 
                type="email" 
                id="email" 
                v-model="email" 
                :placeholder="$t('login.emailPlaceholder')"
                required 
              />
            </div>
          </div>
          
          <div class="form-group">
            <label for="password">{{ $t('login.password') }}</label>
            <div class="input-wrapper">
              <span class="input-icon">🔒</span>
              <input 
                type="password" 
                id="password" 
                v-model="password" 
                :placeholder="$t('login.passwordPlaceholder')"
                required 
              />
            </div>
          </div>
    
          <button type="submit" class="submit-button">{{ $t('login.continue') }}</button>

          <p class="terms-text">
            {{ $t('login.termsPrefix') }}
            <a href="/terms">{{ $t('login.termsLink') }}</a>
            {{ $t('login.termsMiddle') }}
            <a href="/privacy">{{ $t('login.privacyLink') }}</a>
            {{ $t('login.termsSuffix') }}
          </p>
        </form>
      </div>
    </div>

    <footer>
      <p>{{ $t('login.copyright', { year: new Date().getFullYear() }) }}</p>
      <nav>
        <a href="mailto:contact@fastwrap.io">{{ $t('login.contact') }}</a>
      </nav>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

export default {
  name: 'UserLogin',

  setup() {
    const router = useRouter()
    const authStore = useAuthStore()
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const { locale } = useI18n()

    return {
      email,
      password,
      router,
      authStore,
      error,
      locale
    }
  },

  methods: {
    async handleLogin() {
      try {
        const response = await axios.post(`/user/v1/login`, {
          email: this.email,
          password: this.password
        }, {
          withCredentials: true
        })

        const data = response.data

        if (data.email) {
          this.authStore.login({ email: this.email, username: data.username, organization: data.organization })
          this.router.push('/dashboard')
        } else {
          alert(this.$t('login.failedLogin'))
        }
      } catch (error) {
        console.error('Error:', error)
        alert(this.$t('login.errorOccurred'))
      }
    }
  }
}
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  margin-left: 0;
}

.logo-circle {
  height: 50px;
  width: 50px;
  background: #d9d9d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  height: 30px;
  width: 30px;
}

.language-selector {
  position: relative;
}

.language-dropdown {
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: white;
  font-size: 0.9rem;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.language-dropdown:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.8);
}

.language-dropdown option {
  background-color: #1e2a4a;
  color: white;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.login-container {
  width: 100%;
  max-width: 450px;
  padding: 2rem;
  background: white;
  border-radius: 1.5rem;
  margin: 0;
}

h1 {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h2 {
  color: #91908f;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #91908f;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: #d9d9d9;
  border-radius: 5px;
}

.input-icon {
  position: absolute;
  left: 1rem;
  color: #666;
}

input {
  width: 100%;
  padding: 0.8rem 1rem 0.8rem 2.5rem;
  background: transparent;
  border: none;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #91908f;
}

.submit-button {
  width: 100%;
  padding: 0.8rem;
  background: #3E83DB;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin: 2rem 0;
}

.terms-text {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

.terms-text a {
  color: #4285f4;
  text-decoration: none;
}

footer {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 0.9rem;
  margin-top: auto;
}

footer nav a {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .login-container {
    margin: 1rem;
    padding: 1.5rem;
  }

  footer {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  footer nav a {
    display: block;
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1rem;
  }
}
</style>
  