<template>
  <div class="dashboard">
    <!-- Left Sidebar -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar">
          <img 
            v-if="profilePictureUrl" 
            :src="profilePictureUrl" 
            alt="Profile" 
            class="avatar-image"
          >
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
        </div>
        <span class="user-name">{{ authStore.user.username }}</span>
        <button class="logout-btn" @click="handleLogout" title="Logout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <!-- Keep the same nav-menu structure -->
      <nav class="nav-menu">
        <div class="nav-section">
          <h2>{{ $t('sidebar.projects') }}</h2>
          <div 
            v-for="project in projects" 
            :key="project.id" 
            class="nav-item"
            :class="{ active: project.id === currentProjectId }"
            @click="navigateToProjectFromSidebar(project.id)"
          >
            <span>🎬</span>
            {{ project.name }}
          </div>
        </div>
      </nav>
      <div class="sidebar-footer">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <!-- Loading and Error States -->
    <div v-if="loading" class="loading-overlay">
      {{ $t('loading') }}
    </div>
    
    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <!-- Breadcrumb Navigation -->
      <div class="router-bar">
        <div class="breadcrumb">
          <span class="breadcrumb-item" @click="navigateToOrganization">{{ authStore.user.organization }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item" @click="navigateToProject">{{ projectName }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item" @click="navigateToProperties">{{ $t('properties.title') }}</span>
          <span class="separator">/</span>
          <span class="breadcrumb-item">{{ currentConfig.itemName + 's' || 'Items' }}</span>
        </div>
      </div>

      <div class="content-header">
        <div class="header-left">
          <h1>{{ currentConfig.itemName + 's' || 'Items' }}</h1>
        </div>
        <div class="header-right">
          <button class="new-item-btn" @click="openNewItemModal">
            <span class="icon">+</span>
            {{ currentConfig.itemName + 's' || 'Items' }}
          </button>
        </div>
      </div>

      <!-- Dynamic Table Content -->
      <div class="property-content">
        <div class="table-header">
          <div class="table-columns" :style="{ gridTemplateColumns: columnLayout }">
            <div class="checkbox-column">
              <input type="checkbox" @change="toggleAllItems">
            </div>
            <div class="item-number">#</div>
            <template v-for="column in currentColumns" :key="column.key">
              <div 
                class="column sortable" 
                @click="toggleSort(column.key)"
              >
                <span class="column-title">{{ $t(column.label) }}</span>
                <span class="sort-icon" v-if="sortField === column.key">
                  {{ sortOrder === 'asc' ? '↑' : '↓' }}
                </span>
              </div>
            </template>
          </div>
        </div>

        <div class="table-list">
          <div 
            v-for="(item, index) in sortedItems" 
            :key="item.id" 
            class="table-row"
            :style="{ gridTemplateColumns: columnLayout }"
            @click="openEditModal(item)"
          >
            <div class="checkbox-column">
              <input 
                type="checkbox" 
                v-model="item.selected"
                @click.stop
              >
            </div>
            <div class="item-number">{{ index + 1 }}</div>
            <template v-for="column in currentColumns" :key="column.key">
              <div :class="column.key">
                <template v-if="column.type === 'tag'">
                  <span :class="['item-tag', formatFieldValue(item[column.key], column.type)]">
                    {{ formatFieldValue(item[column.key], column.type) }}
                  </span>
                </template>
                <template v-else-if="column.type === 'status' || column.type === 'array-status'">
                  <span :class="['status-tag', formatFieldValue(item[column.key], column.type).class]">
                    {{ formatFieldValue(item[column.key], column.type).text }}
                  </span>
                </template>
                <template v-else>
                  {{ formatFieldValue(item[column.key], column.type) }}
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Add this at the end, just before closing the main div -->
    <div v-if="showEditModal" class="modal-overlay" @click="showEditModal = false">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>Edit Location</h2>
        </div>
        
        <div class="modal-body">
          <form @submit.prevent="saveLocation" class="edit-form">
            <div class="form-group">
              <label for="locationName">Location Name</label>
              <input 
                id="locationName"
                v-model="editingLocation.location_name"
                type="text"
                required
              >
            </div>

            <div class="form-group">
              <label for="locationAddress">Address</label>
              <input 
                id="locationAddress"
                v-model="editingLocation.location_address"
                type="text"
                placeholder="Enter location address"
              >
            </div>

            <div class="form-group">
              <label>Unavailable Periods</label>
              <div class="unavailable-periods">
                <div 
                  v-for="(period, index) in editingLocation.unavailable_periods" 
                  :key="index"
                  class="period-item"
                >
                  <div class="period-dates">
                    <input 
                      type="date" 
                      v-model="period.start_date"
                    >
                    <span>to</span>
                    <input 
                      type="date" 
                      v-model="period.end_date"
                    >
                  </div>
                  <button 
                    type="button" 
                    class="remove-period-btn"
                    @click="removePeriod(index)"
                  >
                    ×
                  </button>
                </div>
                <button 
                  type="button" 
                  class="add-period-btn"
                  @click="addPeriod"
                >
                  + Add Period
                </button>
              </div>
            </div>
          </form>
        </div>
        
        <div class="modal-footer">
          <button class="cancel-btn" @click="showEditModal = false">Cancel</button>
          <button class="save-btn" @click="saveLocation">Save Changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { orderBy } from 'lodash-es'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PropertyDetails',

  setup() {
    const currentProjectId = ref('')
    const currentPropertyId = ref('')
    const loading = ref(false)
    const error = ref(null)
    const searchQuery = ref('')
    const activeFilters = ref({})
    const availableFilters = ref({})
    const items = ref([])
    const sortField = ref('name')
    const sortOrder = ref('asc')
    const profilePictureUrl = ref(null)
    const projects = ref([])
    const authStore = useAuthStore()
    const router = useRouter()
    const { locale } = useI18n()

    const propertyConfigs = {
      locations: {
        itemName: 'Location',
        columns: [
          { key: 'location_name', label: 'properties.columns.name', type: 'text' },
          { key: 'location_type', label: 'properties.columns.type', type: 'tag' },
          { key: 'total_duration', label: 'properties.columns.duration', type: 'number' },
          { key: 'scene_count', label: 'properties.columns.scenes', type: 'number' },
          { key: 'location_address', label: 'properties.columns.complete', type: 'status' },
          { key: 'unavailable_periods', label: 'properties.columns.available', type: 'array-status' }
        ]
      },
      cast: {
        itemName: 'Cast Member',
        columns: [
          { key: 'character_name', label: 'properties.columns.character', type: 'text' },
          { key: 'actor_name', label: 'properties.columns.actorName', type: 'text' },
          { key: 'number_of_scenes', label: 'properties.columns.scenes', type: 'text' },
          { key: 'status', label: 'properties.columns.status', type: 'status' },
          { key: 'availability', label: 'properties.columns.available', type: 'array-status' }
        ]
      },
      prop: {
        itemName: 'Prop',
        columns: [
          { key: 'prop_name', label: 'properties.columns.name', type: 'text' },
          { key: 'prop_type', label: 'properties.columns.category', type: 'tag' },
          { key: 'status', label: 'properties.columns.status', type: 'status' },
        ],
      },
      specials: {
        itemName: 'Special',
        columns: [
          { key: 'special_name', label: 'properties.columns.name', type: 'text' },
          { key: 'special_type', label: 'properties.columns.category', type: 'tag' },
          { key: 'status', label: 'properties.columns.status', type: 'status' },
        ],
      }
    }

    const propertyIdToType = {
      1: 'LOCATION',
      2: 'CAST',
      3: 'PROP',
      4: 'SPECIALS'
      // Add more mappings as needed
    }

    const properties = ref([
      { id: 1, name: 'properties.locations' },
      { id: 2, name: 'properties.cast' },
      { id: 3, name: 'properties.props' },
      { id: 4, name: 'properties.specials' }
    ])

    const currentProperty = computed(() => 
      properties.value.find(p => p.id === currentPropertyId.value)
    )

    const currentConfig = computed(() => {
      const propertyType = propertyIdToType[currentPropertyId.value]?.toLowerCase() || 'locations'
      return propertyConfigs[propertyType] || propertyConfigs.locations
    })

    const currentColumns = computed(() => 
      currentConfig.value.columns
    )

    const columnLayout = computed(() => {
      const columns = [
        'minmax(30px, 30px)',    // checkbox
        'minmax(40px, 40px)',    // number
        ...currentColumns.value.map(() => 'minmax(120px, 1fr)')  // dynamic columns
      ]
      return columns.join(' ')
    })

    const sortedItems = computed(() => {
      if (!sortField.value) return items.value
      return orderBy(items.value, [sortField.value], [sortOrder.value])
    })

    const loadPropertyData = async () => {
      if (!currentPropertyId.value || !currentProjectId.value) return

      loading.value = true
      error.value = null
      
      try {
        const propertyType = propertyIdToType[currentPropertyId.value]
        if (!propertyType) {
          throw new Error('Invalid property type')
        }

        const response = await axios.get(
          `/projects/${currentProjectId.value}/properties`,
          {
            params: {
              property_type: propertyType,
            },
            withCredentials: true
          }
        )

        // Transform the data to match our table structure
        items.value = response.data.properties.map(property => ({
          id: property.id,
          selected: false,
          ...property.fields
        }))

      } catch (err) {
        console.error('Error loading property data:', err)
        error.value = err.response?.data?.message || 'Failed to load property data'
      } finally {
        loading.value = false
      }
    }

    const updateFilters = (filterType, value) => {
      activeFilters.value = {
        ...activeFilters.value,
        [filterType]: value
      }
      loadPropertyData()
    }

    const capitalizeFirstLetter = (string) => {
      if (!string) return ''
      if (typeof string !== 'string') return string
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const formatFieldValue = (value, type) => {
      if (value === null || value === undefined) {
        if (type === 'array-status') {
          return { text: 'Available', class: 'status-complete' }
        }
        if (type === 'status') {
          return { text: 'Missing Address', class: 'status-missing' }
        }
        return '-'
      }
      
      switch (type) {
        case 'number':
          return value.toString()
        case 'tag':
          if (typeof value !== 'string') return String(value)
          return value.replace(/_/g, ' ').toLowerCase()
        case 'status':
          if (currentConfig.value.itemName === 'Cast Member' || 
              currentConfig.value.itemName === 'Prop' || 
              currentConfig.value.itemName === 'Special') {
            return value === true ? 
              { text: 'Active', class: 'status-complete' } : 
              { text: 'Inactive', class: 'status-missing' }
          }
          return { text: 'Complete', class: 'status-complete' }
        case 'array-status':
          return Array.isArray(value) && value.length === 0 ? 
            { text: 'Available', class: 'status-complete' } : 
            { text: 'Unavailable', class: 'status-missing' }
        default:
          if (currentConfig.value.itemName === 'Cast Member' && type === 'text' ||
              currentConfig.value.itemName === 'Prop' && type === 'text' ||
              currentConfig.value.itemName === 'Special' && type === 'text'
          ) {
            return capitalizeFirstLetter(String(value))
          }
          return value
      }
    }

    // Watch for changes that should trigger a reload
    watch([currentPropertyId, sortField, sortOrder], () => {
      loadPropertyData()
    })

    const navigateToOrganization = () => {
      router.push('/dashboard')  // or wherever your organization root is
    }

    const navigateToProperties = () => {
      router.push(`/project/${currentProjectId.value}/properties`)
    }

    const projectName = computed(() => {
      const currentProject = projects.value.find(p => p.id === currentProjectId.value)
      return currentProject ? currentProject.name : ''
    })

    // Add these new refs
    const showEditModal = ref(false)
    const editingLocation = ref(null)

    // Add these new methods
    const openEditModal = (item) => {
      editingLocation.value = JSON.parse(JSON.stringify(item)) // Deep copy
      if (!editingLocation.value.unavailable_periods) {
        editingLocation.value.unavailable_periods = []
      }
      showEditModal.value = true
    }

    const addPeriod = () => {
      editingLocation.value.unavailable_periods.push({
        start_date: '',
        end_date: ''
      })
    }

    const removePeriod = (index) => {
      editingLocation.value.unavailable_periods.splice(index, 1)
    }

    const saveLocation = async () => {
      try {
        const response = await axios.put(
          `/projects/${currentProjectId.value}/properties/${currentPropertyId.value}/${editingLocation.value.id}`,
          editingLocation.value,
          {
            withCredentials: true
          }
        )
        
        // Update the item in the list
        const index = items.value.findIndex(item => item.id === editingLocation.value.id)
        if (index !== -1) {
          items.value[index] = {
            ...response.data,
            selected: items.value[index].selected
          }
        }
        
        showEditModal.value = false
      } catch (err) {
        console.error('Failed to save location:', err)
        error.value = err.response?.data?.message || 'Failed to save location'
      }
    }


    const navigateToProjectFromSidebar = async (projectId) => {
      try {
        const response = await axios.get(`/projects/${projectId}`, {
          withCredentials: true
        })
        if (response.data) {
          router.push(`/project/${projectId}`)
        }
      } catch (error) {
        console.error('Failed to navigate to project:', error)
      }
    }

    return {
      currentProjectId,
      currentPropertyId,
      profilePictureUrl,
      properties,
      items,
      sortedItems,
      sortField,
      sortOrder,
      currentProperty,
      currentConfig,
      currentColumns,
      columnLayout,
      loading,
      error,
      projects,
      projectName,
      searchQuery,
      activeFilters,
      availableFilters,
      updateFilters,
      loadPropertyData,
      formatFieldValue,
      navigateToOrganization,
      navigateToProperties,
      showEditModal,
      editingLocation,
      openEditModal,
      addPeriod,
      removePeriod,
      saveLocation,
      propertyConfigs,
      authStore,
      navigateToProjectFromSidebar,
      locale
    }
  },

  methods: {
    async loadProjects() {
      try {
        const response = await axios.get('/projects', {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    toggleSort(field) {
      if (this.sortField === field) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortField = field
        this.sortOrder = 'asc'
      }
    },

    toggleAllItems(event) {
      this.items.forEach(item => {
        item.selected = event.target.checked
      })
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },




    navigateToProject() {
      this.$router.push(`/project/${this.currentProjectId}`)
    },



    openNewItemModal() {
      // Implement openNewItemModal functionality
    }
  },

  mounted() {
    this.currentProjectId = this.$route.params.projectId
    this.currentPropertyId = this.$route.params.propertyId
    this.loadProjects()
  }
}
</script>

<style scoped>
/* Layout */
.dashboard {
  display: flex;
  height: 100vh;
  background: #1a1a1a;
  color: #ffffff;
}

.sidebar {
  width: 240px;
  background: #2c2c2c;
  border-right: 1px solid #3e3e3e;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
}

/* Controls Section */
.controls-section {
  margin-bottom: 24px;
}

.search-bar input {
  width: 100%;
  padding: 8px 12px;
  background: #2c2c2c;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
}

/* Table Styles */
.property-content {
  background: #2c2c2c;
  border-radius: 8px;
  border: 1px solid #3e3e3e;
}

.table-header {
  border-bottom: 1px solid #3e3e3e;
  padding: 12px 0;
}

.table-columns, .table-row {
  display: grid;
  grid-template-columns: 40px 40px 2fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0 16px;
}

.table-columns {
  color: #888;
  font-size: 13px;
  font-weight: 500;
}

.table-row {
  height: 48px;
  border-bottom: 1px solid #3e3e3e;
}

.table-row:last-child {
  border-bottom: none;
}

.table-row:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* Column Styles */
.checkbox-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-number {
  color: #888;
  font-size: 13px;
}

/* Tag Styles */
.item-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.item-tag.partial_day {
  background: rgba(0, 122, 255, 0.1);
  color: #007AFF;
}

.item-tag.full_day {
  background: rgba(52, 199, 89, 0.1);
  color: #34C759;
}

.item-tag.night {
  background: rgba(88, 86, 214, 0.1);
  color: #5856D6;
}

/* Button Styles */
.new-item-btn {
  background: #007AFF;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-item-btn:hover {
  background: #0066CC;
}

/* Header Styles */
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header-left h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

/* Breadcrumb Styles */
.router-bar {
  margin-bottom: 24px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #888;
}

.breadcrumb-item {
  cursor: pointer;
  color: #888;
}

.breadcrumb-item:hover {
  color: #ffffff;
}

.separator {
  color: #888;
}

/* Loading and Error States */
.loading-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  z-index: 1000;
}

.error-message {
  background: rgba(255, 59, 48, 0.1);
  color: #FF3B30;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 24px;
}

.status-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.status-tag.status-missing {
  background: rgba(255, 59, 48, 0.1);
  color: #e68815; /* Changed from #FF3B30 to #FF3B30 */
}

.status-tag.status-complete {
  background: rgba(52, 199, 89, 0.1);
  color: #34C759;
}

/* Add these new modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #2c2c2c;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #3e3e3e;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid #3e3e3e;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #888;
}

.form-group input {
  padding: 8px 12px;
  border: 1px solid #3e3e3e;
  border-radius: 4px;
  background: #2c2c2c;
  color: white;
  font-size: 14px;
}

.unavailable-periods {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.period-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.period-dates {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.period-dates input {
  flex: 1;
}

.remove-period-btn {
  padding: 4px 8px;
  border: none;
  background: rgba(255, 59, 48, 0.1);
  color: #FF3B30;
  border-radius: 4px;
  cursor: pointer;
}

.add-period-btn {
  padding: 8px;
  border: 1px dashed #3e3e3e;
  background: transparent;
  color: #007AFF;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
}

.cancel-btn, .save-btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.cancel-btn {
  background: transparent;
  border: 1px solid #3e3e3e;
  color: white;
}

.save-btn {
  background: #007AFF;
  border: none;
  color: white;
}

.save-btn:hover {
  background: #0066CC;
}

.table-row {
  cursor: pointer;
}
</style>
