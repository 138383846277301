import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        loggedIn: false,
        user: {
            username: '',
            organization: null,
            profilePictureUrl: null,
        },
    }),

    actions: {
        login(userData) {
            this.loggedIn = true;
            this.user = {
                username: userData.username,
                organization: userData.organization,
                profilePictureUrl: userData.profilePictureUrl || null,
            };
            localStorage.setItem('loggedIn', 'true');
            localStorage.setItem('userData', JSON.stringify(this.user));
        },
        logout() {
            this.loggedIn = false;
            this.user = {
                username: '',
                organization: null,
                profilePictureUrl: null,
            };
            localStorage.removeItem('loggedIn')
            localStorage.removeItem('userData')
        },
        checkAuth() {
            this.loggedIn = localStorage.getItem('loggedIn') === 'true';
            if (this.loggedIn) {
                const userData = JSON.parse(localStorage.getItem('userData'));
                if (userData) {
                    this.user = userData;
                }
            }
        }
    }
});