<template>
  <div class="dashboard">
    <!-- Left Sidebar -->
    <div class="sidebar">
      <div class="user-section">
        <div class="user-avatar">
          <img 
            v-if="profilePictureUrl" 
            :src="profilePictureUrl" 
            alt="Profile" 
            class="avatar-image"
          >
          <span v-else>{{ authStore.user.username.charAt(0) }}</span>
        </div>
        <span class="user-name">{{ authStore.user.username }}</span>
        <button class="logout-btn" @click="handleLogout" title="Logout">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 17L21 12L16 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M21 12H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <nav class="nav-menu">
        <div class="nav-section">
          <h2>{{ $t('sidebar.projects') }}</h2>
          <div 
            v-for="project in projects" 
            :key="project.id" 
            class="nav-item"
            :class="{ active: project.id === currentProjectId }"
            @click="navigateToProject(project.id)"
          >
            <span>🎬</span>
            {{ project.name }}
          </div>
        </div>
      </nav>

      <!-- Add language selector at bottom of sidebar -->
      <div class="sidebar-footer">
        <select v-model="locale" class="language-dropdown">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </select>
      </div>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <div class="header-gradient">
        <div class="router-bar">
          <div class="breadcrumb">
            <span class="breadcrumb-item" @click="navigateToOrganization">{{ authStore.user.organization }}</span>
            <span class="separator">/</span>
            <span class="breadcrumb-item active">{{ projectName }}</span>
          </div>
        </div>
      </div>

      <div class="content-header">
        <div class="header-left">
          <h1>{{ projectName }}</h1>
        </div>
      </div>

      <div class="projects-container">
        <div class="project-grid">
          <div class="project-card" @click="navigateToFeature('script-breakdown')">
            <h2>{{ $t('features.sceneBreakdown.title') }}</h2>
            <p>{{ $t('features.sceneBreakdown.description') }}</p>
          </div>

          <div class="project-card" @click="navigateToFeature('shooting-schedule')">
            <h2>{{ $t('features.shootingSchedule.title') }}</h2>
            <p>{{ $t('features.shootingSchedule.description') }}</p>
          </div>

          <div class="project-card" @click="navigateToFeature('properties')">
            <h2>{{ $t('features.properties.title') }}</h2>
            <p>{{ $t('features.properties.description') }}</p>
          </div>

          <div class="project-card" @click="navigateToFeature('contacts')">
            <h2>{{ $t('features.contacts.title') }}</h2>
            <p>{{ $t('features.contacts.description') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import { useI18n } from 'vue-i18n'
import axios from 'axios'

export default {
  name: 'ProjectOverview',

  setup() {
    const authStore = useAuthStore()
    const router = useRouter()
    const projects = ref([])
    const currentProjectId = ref('')
    const profilePictureUrl = ref(null)
    const { locale } = useI18n()

    // Use computed property for project name
    const projectName = computed(() => {
      const currentProject = projects.value.find(p => p.id === currentProjectId.value)
      return currentProject ? currentProject.name : ''
    })

    return {
      projects,
      currentProjectId,
      profilePictureUrl,
      projectName,
      router,
      authStore,
      locale
    }
  },

  methods: {
    async loadProjects() {
      try {
        const response = await axios.get(`/projects`, {
          withCredentials: true
        })
        this.projects = response.data
      } catch (error) {
        console.error('Failed to load projects:', error)
      }
    },

    navigateToProject(projectId) {
      this.router.push(`/project/${projectId}`)
    },

    handleLogout() {
      this.router.push('/login')
    },

    navigateToOrganization() {
      this.router.push('/dashboard')
    },

    navigateToFeature(feature) {
      this.router.push(`/project/${this.currentProjectId}/${feature}`)
    }
  },

  mounted() {
    this.currentProjectId = this.$route.params.projectId
    this.loadProjects()
  }
}
</script>

<style scoped>
.main-content {
  flex: 1;
  padding: 24px 24px 24px 0;
  min-width: 0;
  position: relative;
  height: 100vh;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 16px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-gradient {
  height: 200px;
  background: linear-gradient(180deg, 
    rgba(55, 65, 81, 1) 0%,
    rgba(30, 30, 30, 1) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.projects-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: calc(100% - 100px);
  z-index: 1;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  padding: 24px;
  max-width: 800px;
  margin: 0 auto;
}

.project-card {
  background: #2c2c2c;
  border-radius: 8px;
  padding: 40px 32px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.project-card h2 {
  font-size: 24px;
  margin-bottom: 16px;
  color: #ffffff;
}

.project-card p {
  color: #888;
  font-size: 16px;
  max-width: 80%;
  margin: 0 auto;
}

/* Remove the duplicate project title */
h2.project-title {
  display: none;
}

.router-bar {
  position: absolute;
  top: 5px;
  left: 20px; /* Position next to sidebar */
  z-index: 2;
  padding: 8px 0;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
  font-size: 14px;
}

.breadcrumb-item {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.breadcrumb-item:hover {
  opacity: 1;
}

.breadcrumb-item.active {
  opacity: 1;
  font-weight: 500;
  cursor: default;
}

.separator {
  color: #888;
  margin: 0 4px;
}

.sidebar {
  width: 200px;
  background: #2c2c2c;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #3e3e3e;
  position: sticky;
  top: 0;
  height: auto;
  z-index: 2;
}

.sidebar-footer {
  margin-top: auto;
  padding: 16px;
}

.language-dropdown {
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 1rem;
  color: white;
  font-size: 0.9rem;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
}

.language-dropdown:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.8);
}

.language-dropdown option {
  background-color: #1e2a4a;
  color: white;
}
</style>
  